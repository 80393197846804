import { coreApi as api } from 'api/coreApi';
import {
  ApplyRebateRequestTypes,
  CartTotalsRequestTypes,
  CartTotalsResponseTypes,
} from './types';

const addTagTypes = ['totalsInfo'];

const checkoutApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      onGetCartTotals: build.mutation({
        query: (queryArg) => {
          const { township_id, isLoggedIn, cartMaskId } = queryArg;
          const requestUrl = !!isLoggedIn
            ? `carts/mine/totals-information`
            : `guest-carts/${cartMaskId}/totals-information`;

          return {
            url: `/cart/${requestUrl}`,
            method: 'POST',
            body: {
              township_id,
            },
          };
        },
      }),
      onApplyRebate: build.mutation({
        query: ({ method, quote_id, rebate }) => ({
          url: `cart/carts/mine/rebates`,
          method,
          body: {
            quote_id,
            ...(rebate > 0 && { rebate }),
          },
        }),
      }),
    }),
    overrideExisting: false,
  });

export const { useOnGetCartTotalsMutation, useOnApplyRebateMutation } =
  checkoutApi;

export default checkoutApi;
