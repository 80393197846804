import config from 'global-config';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import request from 'utils/request';
import URL from 'constant/urls';
import axios from 'axios';

import * as actions from './actions';
import * as constants from './constants';
import * as globalActions from 'containers/Landers/actions';
import {
  getAutosuggestUrl,
  convertAutoSuggestResponseObject,
} from 'utils/unbxdHelper';

import * as searchSelectors from './selectors';
import { get } from 'lodash';
import { getStoreViewUrl } from 'utils/request';

const stringifyComplexSearchParams = (params = {}) => {
  return Object.entries(params).reduce((prev, cur) => {
    const [key, val] = cur;
    if (val === null) {
      return [...prev];
    }
    const part = `searchCriteria[${key}]=${val}`;
    return [...prev, part];
  }, []);
};

function* onLoadSearchResultPopup(action) {
  const keyword = action.keyword;
  const category = action.categoryId;
  const requestURL = `${getStoreViewUrl()}/landers-search-suggestion/query/${keyword}/catid/${
    category || 0
  }`;
  yield delay(2000);
  yield put(
    globalActions.showLoader({
      isLoadingSearch: true,
    }),
  );
  try {
    let res = yield call(request, requestURL, {
      method: 'GET',
    });

    res.brands = [];
    res.category = [];

    yield put(actions.searchHeaderSuccess(res));
    yield put(globalActions.hideLoader());
  } catch (err) {
    yield put(actions.searchHeaderFailed(err));
    yield put(globalActions.hideLoader());
  }
}

const callUnbxd = async (url) => {
  return axios.get(url);
};

function* onLoadSearchResultPopupSelector(action) {
  const source = get(action, 'autoSuggestApi');

  switch (source) {
    case 'unbxd':
      yield call(onLoadUnbxdAutoSuggestPopup, action);
      break;
    default:
      yield call(onLoadSearchResultPopup, action);
      break;
  }
}

function* onLoadUnbxdAutoSuggestPopup(action) {
  const keyword = action.keyword;
  const category = action.categoryId;
  const requestURL = getAutosuggestUrl(keyword, category);
  yield delay(200);
  yield put(
    globalActions.showLoader({
      isLoadingSearch: true,
    }),
  );
  try {
    const result = yield callUnbxd(requestURL);

    let res = convertAutoSuggestResponseObject(result.data);
    yield put(actions.searchHeaderSuccess(res));
    yield put(globalActions.hideLoader());
  } catch (err) {
    console.log(err);
    yield put(actions.searchHeaderFailed(err));
    yield put(globalActions.hideLoader());
  }
}

function* onLoadSearchResults(action) {
  const {
    keyword,
    categoryId = 0,
    pageSize,
    filterOptions,
    isLoadMore,
  } = action.payload;
  let requestURL = `${getStoreViewUrl()}/landers-search-result`;
  let currentPage = yield select((state) =>
    searchSelectors.makeSelectSearchCurrentPage()(state),
  );
  if (isLoadMore) {
    currentPage++;
  } else {
    currentPage = 1;
  }
  let params = {
    query: keyword,
    categoryId: categoryId,
    pageSize,
    currentPage,
  };
  if (window.location.pathname === URL.specialProduct) {
    params.isOnsale = 1;
    delete params.query;
    delete params.categoryId;
    requestURL = `${config.apiUrl}/landers-product-list-page`;
  }

  params.productListDir =
    get(filterOptions, 'sortCondition.direction') || 'ASC';
  params.productListOrder =
    get(filterOptions, 'sortCondition.value') || 'lander_store_quantity';
  params.ldDisplay = get(filterOptions, 'filterCondition.value') || 'quantity';

  const paramStringArr = stringifyComplexSearchParams(params) || [];
  requestURL = `${requestURL}?${paramStringArr.join('&')}`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });

    yield put(
      actions.loadSearchResultsActionSuccess(res, currentPage, isLoadMore),
    );
  } catch (err) {
    yield put(actions.loadSearchResultsActionFail(err));
  }
}

function* onLoadListCategorySearch() {
  const requestURL = `${config.apiUrl}/landers-megamenu-load-cms-url`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });
    yield put(actions.saveListCategorySearch(res));
  } catch (err) {}
}

export default function* searchSaga() {
  yield takeLatest(constants.SEARCH_HEADER, onLoadSearchResultPopupSelector);
  yield takeLatest(constants.LOAD_SEARCH_RESULTS, onLoadSearchResults);
  yield takeLatest(
    constants.LOAD_LIST_CATEGORY_SEARCH,
    onLoadListCategorySearch,
  );
}
