import URL from 'constant/urls';
import { LOCATION_LABEL_SIZE } from 'constant';

// All mobile screens
export const SCREEN = {
  HOME: '/',
  CHECKOUT_CART: '/checkout/cart',
  CHECKOUT_TIMESLOTS: '/checkout/delivery-timeslots',
  CHECKOUT_DELIVERY_ADDRESS: '/checkout/delivery-address',
  CHECKOUT_PICKUP: '/checkout/pickup-details',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_PAYMENT_SUCCESS: '/checkout/onepage/success',
  CHECKOUT_PAYMENT_FAILED: '/checkout/onepage/failure',
  CHECKOUT_PAYMENT_WEBVIEW: '/checkout/payment-webview',
  ACCOUNT: '/customer/account',
  MY_PAYMENT_METHODS: 'customer/payment-methods',
  MY_ORDERS: '/sales/order/history',
  ACCOUNT_NEW_ADDRESS: '/customer/address/new',
  ACCOUNT_EDIT_ADDRESS: '/customer/address',
  MY_MEMBERSHIP: '/my-membership',
  MEMBERSHIP_LIST: '/lofcustomermembership/buy',
  ONSALE: '/special/hot-offers.html',
  ALLSHOPPINGLISTS: '/shopping-list',
  RESET_PASSWORD: '/customer/account/createpassword',
  ORDER_TRACK_AND_TRACE: '/track-and-trace',
  ADDRESS_LIST: '/customer/address',
  SEARCH_RESULT: '/catalogsearch/result/',
  HELP_CENTER: '/help-center',
};

export const SCREEN_TITLE = {
  [SCREEN.HOME]: 'Home page',
  [SCREEN.CHECKOUT_CART]: 'Cart page',
  [SCREEN.CHECKOUT_DELIVERY_ADDRESS]: 'Delivery Address',
  [SCREEN.CHECKOUT_TIMESLOTS]: 'Delivery Timeslot',
  [SCREEN.CHECKOUT_PICKUP]: 'Pickup Details',
  [SCREEN.CHECKOUT_PAYMENT]: 'Payment Method',
  [SCREEN.CHECKOUT_PAYMENT_WEBVIEW]: 'Payment Gateway',
  [SCREEN.CHECKOUT_PAYMENT_SUCCESS]: 'Payment Success',
  [SCREEN.CHECKOUT_PAYMENT_FAILED]: 'Payment Failed',
  //
  [SCREEN.ONSALE]: 'Hot Offers',
  [SCREEN.ALLSHOPPINGLISTS]: 'Shopping Lists',
  [SCREEN.MEMBERSHIP_LIST]: 'Apply Membership',
  [SCREEN.RESET_PASSWORD]: 'Reset Password',
  [SCREEN.ACCOUNT]: 'My Account',
  [SCREEN.MY_PAYMENT_METHODS]: 'My Payment Methods',
  [SCREEN.MY_ORDERS]: 'My Orders',
  [SCREEN.ORDER_TRACK_AND_TRACE]: 'Track and Trace',
  [SCREEN.MY_MEMBERSHIP]: 'My Membership',
  [SCREEN.ADDRESS_LIST]: 'Address Book',
  [SCREEN.ACCOUNT_NEW_ADDRESS]: 'Add New Address',
  [SCREEN.CATEGORY]: 'Category',
  [SCREEN.PROMOTION]: 'Promotion',
  [SCREEN.ORDER_DETAIL]: 'Order Detail',
  [SCREEN.SEARCH_RESULT]: 'Search Result',
  [SCREEN.HELP_CENTER]: 'Help Center',
};

export const ID_PAST_PURCHASES_LIST = 'ID_PAST_PURCHASES_LIST';

export const TRACKING_PAGE_PRODUCT = {
  home: 'Homepage',
  search: 'Search page',
  category: 'Category page',
  cart: 'Cart Summary',
  shopping_list: 'Shopping list',
};
export const TRACKING_ELEMENT_PRODUCT = {
  suggested: 'Suggested products',
  bestsellers: 'Bestsellers',
  search_bar: 'Search Bar',
  mini_cart: 'Mini Cart',
};
export const TYPE_TRACKING_PRODUCT = {
  pdp: 'PDP',
  product_card: 'Product Card',
};

export const CARD_MASK = '9999-99999999-99';
export const CARD_MASK_CALTEX = '9999-9999-9999-9999';

export const PAYMENT_METHOD = {
  CASH: 'cashondelivery',
  G_CASH: 'gcash',
  FREE: 'free',
  MAYA_VAULT: 'maya_vault',
  MAYA_CHECKOUT: 'paymaya_checkout',
};

//Social Type
export const SOCIAL_LOGIN = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
};

// All Async Storage keys
export const STORAGEKEY = {
  // For Checkout
  CART_DATA: '@Checkout:cartData',
};

//  MEMBERSHIP CARD PACKAGE
export const MEMBERSHIP_CARD_PACKAGE = {
  PREMIUM_PACKAGE: 'PREMIUM',
  BUSINESS_PACKAGE: 'BUSINESS',
  EXECUTIVE_PACKAGE: 'EXECUTIVE',
};

//  MEMBERSHIP CART TYPES
export const MEMBERSHIP_CARD_TYPES = {
  Premium: 'Premium',
  Business: 'Business',
};

export const MEMBERSHIP_CARD_TYPE = {
  PREMIUM_LANDERS_CC_TYPE: 'PREMIUM_WITH_LANDERS_CCARD',
  PREMIUM_TYPE: 'NEW_PREMIUM_CARD',
  BUSINESS_TYPE: 'NEW_BUSINESS_CARD',
  EXTEND_TYPE: 'EXTEND_CARD',
  RENEW_TYPE: 'RENEW_CARD',
  UPGRADE_RENEW_EXECUTIVE_CARD: 'UPGRADE_RENEW_EXECUTIVE_CARD',
  EXECUTIVE_TYPE: 'EXECUTIVE',
};

export const MEMBERSHIP_TYPES = [
  'NEW_PREMIUM_CARD',
  'NEW_BUSINESS_CARD',
  'EXTEND_CARD',
  'RENEW_CARD',
];

export const MEMBERSHIP_CARD_BIZ = [
  'NEW_BUSINESS_CARD',
  'UPGRADE_RENEW_EXECUTIVE_CARD',
];

// BUY TYPE NEW MEMBERSHIP CART
export const MEMBERSHIP_CARD_BUY_TYPE = {
  BUY_NEW: 'buy-new',
  RENEW: 'renew',
  EXTEND: 'extend',
};

export const PAGE_CONFIG = {
  currentPage: 1,
  pageSize: 12,
};

// SPECIAL INSTRUCTION WHEN CHECKOUT CART
export const SPECIAL_INSTRUCTIONS = [
  {
    value: 'Please select (if any)',
  },
  {
    value: 'Please bring valid ID to present to the guard',
  },
  {
    value: 'Please bring valid ID. Parking fee is required',
  },
  {
    value: 'Please do not ring the doorbell',
  },
  {
    value: 'Please call when you arrive at the gate/lobby',
  },
  {
    value: 'Leave order in the lobby / with the guard',
  },
  {
    value:
      "I'm not at home, I will leave an authorization letter to my representative",
  },
  {
    value: 'Do not leave my parcel to the guard, please call when you arrive',
  },
  {
    value: "Please SMS upon arrival. I can't answer the phone",
  },
  {
    value: 'Please use the doorbell when you arrive',
  },
];

// GENDERS
export const GENDERS = [
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name: 'Female',
  },
];

// MARTIAL STATUS
export const MARITAL_STATUS = [
  {
    id: 1,
    name: 'Single',
  },
  {
    id: 2,
    name: 'Married',
  },
];

// CITYZENSHIP
export const CITYZENSHIPS = [
  {
    id: 0,
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    id: 1,
    name: 'Åland Islands',
    code: 'AX',
  },
  {
    id: 2,
    name: 'Albania',
    code: 'AL',
  },
  {
    id: 3,
    name: 'Algeria',
    code: 'DZ',
  },
  {
    id: 4,
    name: 'American Samoa',
    code: 'AS',
  },
  {
    id: 5,
    name: 'AndorrA',
    code: 'AD',
  },
  {
    id: 6,
    name: 'Angola',
    code: 'AO',
  },
  {
    id: 7,
    name: 'Anguilla',
    code: 'AI',
  },
  {
    id: 8,
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    id: 10,
    name: 'Argentina',
    code: 'AR',
  },
  {
    id: 11,
    name: 'Armenia',
    code: 'AM',
  },
  {
    id: 12,
    name: 'Aruba',
    code: 'AW',
  },
  {
    id: 13,
    name: 'Australia',
    code: 'AU',
  },
  {
    id: 14,
    name: 'Austria',
    code: 'AT',
  },
  {
    id: 15,
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    id: 16,
    name: 'Bahamas',
    code: 'BS',
  },
  {
    id: 17,
    name: 'Bahrain',
    code: 'BH',
  },
  {
    id: 18,
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    id: 19,
    name: 'Barbados',
    code: 'BB',
  },
  {
    id: 20,
    name: 'Belarus',
    code: 'BY',
  },
  {
    id: 21,
    name: 'Belgium',
    code: 'BE',
  },
  {
    id: 22,
    name: 'Belize',
    code: 'BZ',
  },
  {
    id: 23,
    name: 'Benin',
    code: 'BJ',
  },
  {
    id: 24,
    name: 'Bermuda',
    code: 'BM',
  },
  {
    id: 25,
    name: 'Bhutan',
    code: 'BT',
  },
  {
    id: 26,
    name: 'Bolivia',
    code: 'BO',
  },
  {
    id: 27,
    name: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    id: 28,
    name: 'Botswana',
    code: 'BW',
  },
  {
    id: 29,
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    id: 30,
    name: 'Brazil',
    code: 'BR',
  },
  {
    id: 31,
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    id: 32,
    name: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    id: 33,
    name: 'Bulgaria',
    code: 'BG',
  },
  {
    id: 34,
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    id: 35,
    name: 'Burundi',
    code: 'BI',
  },
  {
    id: 36,
    name: 'Cambodia',
    code: 'KH',
  },
  {
    id: 37,
    name: 'Cameroon',
    code: 'CM',
  },
  {
    id: 38,
    name: 'Canada',
    code: 'CA',
  },
  {
    id: 39,
    name: 'Cape Verde',
    code: 'CV',
  },
  {
    id: 40,
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    id: 41,
    name: 'Central African Republic',
    code: 'CF',
  },
  {
    id: 42,
    name: 'Chad',
    code: 'TD',
  },
  {
    id: 43,
    name: 'Chile',
    code: 'CL',
  },
  {
    id: 44,
    name: 'China',
    code: 'CN',
  },
  {
    id: 45,
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    id: 46,
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    id: 47,
    name: 'Colombia',
    code: 'CO',
  },
  {
    id: 48,
    name: 'Comoros',
    code: 'KM',
  },
  {
    id: 49,
    name: 'Congo',
    code: 'CG',
  },
  {
    id: 50,
    name: 'Congo, The Democratic Republic of the',
    code: 'CD',
  },
  {
    id: 51,
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    id: 52,
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    id: 53,
    name: "Cote D'Ivoire",
    code: 'CI',
  },
  {
    id: 54,
    name: 'Croatia',
    code: 'HR',
  },
  {
    id: 55,
    name: 'Cuba',
    code: 'CU',
  },
  {
    id: 56,
    name: 'Cyprus',
    code: 'CY',
  },
  {
    id: 57,
    name: 'Czech Republic',
    code: 'CZ',
  },
  {
    id: 58,
    name: 'Denmark',
    code: 'DK',
  },
  {
    id: 59,
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    id: 60,
    name: 'Dominica',
    code: 'DM',
  },
  {
    id: 61,
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    id: 62,
    name: 'Ecuador',
    code: 'EC',
  },
  {
    id: 63,
    name: 'Egypt',
    code: 'EG',
  },
  {
    id: 64,
    name: 'El Salvador',
    code: 'SV',
  },
  {
    id: 65,
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    id: 66,
    name: 'Eritrea',
    code: 'ER',
  },
  {
    id: 67,
    name: 'Estonia',
    code: 'EE',
  },
  {
    id: 68,
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    id: 69,
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  {
    id: 70,
    name: 'Faroe Islands',
    code: 'FO',
  },
  {
    id: 71,
    name: 'Fiji',
    code: 'FJ',
  },
  {
    id: 72,
    name: 'Finland',
    code: 'FI',
  },
  {
    id: 73,
    name: 'France',
    code: 'FR',
  },
  {
    id: 74,
    name: 'French Guiana',
    code: 'GF',
  },
  {
    id: 75,
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    id: 76,
    name: 'French Southern Territories',
    code: 'TF',
  },
  {
    id: 77,
    name: 'Gabon',
    code: 'GA',
  },
  {
    id: 78,
    name: 'Gambia',
    code: 'GM',
  },
  {
    id: 79,
    name: 'Georgia',
    code: 'GE',
  },
  {
    id: 80,
    name: 'Germany',
    code: 'DE',
  },
  {
    id: 81,
    name: 'Ghana',
    code: 'GH',
  },
  {
    id: 82,
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    id: 83,
    name: 'Greece',
    code: 'GR',
  },
  {
    id: 84,
    name: 'Greenland',
    code: 'GL',
  },
  {
    id: 85,
    name: 'Grenada',
    code: 'GD',
  },
  {
    id: 86,
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    id: 87,
    name: 'Guam',
    code: 'GU',
  },
  {
    id: 88,
    name: 'Guatemala',
    code: 'GT',
  },
  {
    id: 89,
    name: 'Guernsey',
    code: 'GG',
  },
  {
    id: 90,
    name: 'Guinea',
    code: 'GN',
  },
  {
    id: 91,
    name: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    id: 92,
    name: 'Guyana',
    code: 'GY',
  },
  {
    id: 93,
    name: 'Haiti',
    code: 'HT',
  },
  {
    id: 94,
    name: 'Heard Island and Mcdonald Islands',
    code: 'HM',
  },
  {
    id: 95,
    name: 'Holy See (Vatican City State)',
    code: 'VA',
  },
  {
    id: 96,
    name: 'Honduras',
    code: 'HN',
  },
  {
    id: 97,
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    id: 98,
    name: 'Hungary',
    code: 'HU',
  },
  {
    id: 99,
    name: 'Iceland',
    code: 'IS',
  },
  {
    id: 100,
    name: 'India',
    code: 'IN',
  },
  {
    id: 101,
    name: 'Indonesia',
    code: 'ID',
  },
  {
    id: 102,
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
  },
  {
    id: 103,
    name: 'Iraq',
    code: 'IQ',
  },
  {
    id: 104,
    name: 'Ireland',
    code: 'IE',
  },
  {
    id: 105,
    name: 'Isle of Man',
    code: 'IM',
  },
  {
    id: 106,
    name: 'Israel',
    code: 'IL',
  },
  {
    id: 107,
    name: 'Italy',
    code: 'IT',
  },
  {
    id: 108,
    name: 'Jamaica',
    code: 'JM',
  },
  {
    id: 109,
    name: 'Japan',
    code: 'JP',
  },
  {
    id: 110,
    name: 'Jersey',
    code: 'JE',
  },
  {
    id: 111,
    name: 'Jordan',
    code: 'JO',
  },
  {
    id: 112,
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    id: 113,
    name: 'Kenya',
    code: 'KE',
  },
  {
    id: 114,
    name: 'Kiribati',
    code: 'KI',
  },
  {
    id: 115,
    name: "Korea, Democratic People'S Republic of",
    code: 'KP',
  },
  {
    id: 116,
    name: 'Korea, Republic of',
    code: 'KR',
  },
  {
    id: 117,
    name: 'Kuwait',
    code: 'KW',
  },
  {
    id: 118,
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    id: 119,
    name: "Lao People'S Democratic Republic",
    code: 'LA',
  },
  {
    id: 120,
    name: 'Latvia',
    code: 'LV',
  },
  {
    id: 121,
    name: 'Lebanon',
    code: 'LB',
  },
  {
    id: 122,
    name: 'Lesotho',
    code: 'LS',
  },
  {
    id: 123,
    name: 'Liberia',
    code: 'LR',
  },
  {
    id: 124,
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
  },
  {
    id: 125,
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    id: 126,
    name: 'Lithuania',
    code: 'LT',
  },
  {
    id: 127,
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    id: 128,
    name: 'Macao',
    code: 'MO',
  },
  {
    id: 129,
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
  },
  {
    id: 130,
    name: 'Madagascar',
    code: 'MG',
  },
  {
    id: 131,
    name: 'Malawi',
    code: 'MW',
  },
  {
    id: 132,
    name: 'Malaysia',
    code: 'MY',
  },
  {
    id: 133,
    name: 'Maldives',
    code: 'MV',
  },
  {
    id: 134,
    name: 'Mali',
    code: 'ML',
  },
  {
    id: 135,
    name: 'Malta',
    code: 'MT',
  },
  {
    id: 136,
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    id: 137,
    name: 'Martinique',
    code: 'MQ',
  },
  {
    id: 138,
    name: 'Mauritania',
    code: 'MR',
  },
  {
    id: 139,
    name: 'Mauritius',
    code: 'MU',
  },
  {
    id: 140,
    name: 'Mayotte',
    code: 'YT',
  },
  {
    id: 141,
    name: 'Mexico',
    code: 'MX',
  },
  {
    id: 142,
    name: 'Micronesia, Federated States of',
    code: 'FM',
  },
  {
    id: 143,
    name: 'Moldova, Republic of',
    code: 'MD',
  },
  {
    id: 144,
    name: 'Monaco',
    code: 'MC',
  },
  {
    id: 145,
    name: 'Mongolia',
    code: 'MN',
  },
  {
    id: 146,
    name: 'Montserrat',
    code: 'MS',
  },
  {
    id: 147,
    name: 'Morocco',
    code: 'MA',
  },
  {
    id: 148,
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    id: 149,
    name: 'Myanmar',
    code: 'MM',
  },
  {
    id: 150,
    name: 'Namibia',
    code: 'NA',
  },
  {
    id: 151,
    name: 'Nauru',
    code: 'NR',
  },
  {
    id: 152,
    name: 'Nepal',
    code: 'NP',
  },
  {
    id: 153,
    name: 'Netherlands',
    code: 'NL',
  },
  {
    id: 154,
    name: 'Netherlands Antilles',
    code: 'AN',
  },
  {
    id: 155,
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    id: 156,
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    id: 157,
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    id: 158,
    name: 'Niger',
    code: 'NE',
  },
  {
    id: 159,
    name: 'Nigeria',
    code: 'NG',
  },
  {
    id: 160,
    name: 'Niue',
    code: 'NU',
  },
  {
    id: 161,
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    id: 162,
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    id: 163,
    name: 'Norway',
    code: 'NO',
  },
  {
    id: 164,
    name: 'Oman',
    code: 'OM',
  },
  {
    id: 165,
    name: 'Pakistan',
    code: 'PK',
  },
  {
    id: 166,
    name: 'Palau',
    code: 'PW',
  },
  {
    id: 167,
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
  },
  {
    id: 168,
    name: 'Panama',
    code: 'PA',
  },
  {
    id: 169,
    name: 'Papua New Guinea',
    code: 'PG',
  },
  {
    id: 170,
    name: 'Paraguay',
    code: 'PY',
  },
  {
    id: 171,
    name: 'Peru',
    code: 'PE',
  },
  {
    id: 172,
    name: 'Philippines',
    code: 'PH',
  },
  {
    id: 173,
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    id: 174,
    name: 'Poland',
    code: 'PL',
  },
  {
    id: 175,
    name: 'Portugal',
    code: 'PT',
  },
  {
    id: 176,
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    id: 177,
    name: 'Qatar',
    code: 'QA',
  },
  {
    id: 178,
    name: 'Reunion',
    code: 'RE',
  },
  {
    id: 179,
    name: 'Romania',
    code: 'RO',
  },
  {
    id: 180,
    name: 'Russian Federation',
    code: 'RU',
  },
  {
    id: 181,
    name: 'RWANDA',
    code: 'RW',
  },
  {
    id: 182,
    name: 'Saint Helena',
    code: 'SH',
  },
  {
    id: 183,
    name: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    id: 184,
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    id: 185,
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    id: 186,
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    id: 187,
    name: 'Samoa',
    code: 'WS',
  },
  {
    id: 188,
    name: 'San Marino',
    code: 'SM',
  },
  {
    id: 189,
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    id: 190,
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    id: 191,
    name: 'Senegal',
    code: 'SN',
  },
  {
    id: 192,
    name: 'Serbia and Montenegro',
    code: 'CS',
  },
  {
    id: 193,
    name: 'Seychelles',
    code: 'SC',
  },
  {
    id: 194,
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    id: 195,
    name: 'Singapore',
    code: 'SG',
  },
  {
    id: 196,
    name: 'Slovakia',
    code: 'SK',
  },
  {
    id: 197,
    name: 'Slovenia',
    code: 'SI',
  },
  {
    id: 198,
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    id: 199,
    name: 'Somalia',
    code: 'SO',
  },
  {
    id: 200,
    name: 'South Africa',
    code: 'ZA',
  },
  {
    id: 201,
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  {
    id: 202,
    name: 'Spain',
    code: 'ES',
  },
  {
    id: 203,
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    id: 204,
    name: 'Sudan',
    code: 'SD',
  },
  {
    id: 205,
    name: 'Suriname',
    code: 'SR',
  },
  {
    id: 206,
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    id: 207,
    name: 'Swaziland',
    code: 'SZ',
  },
  {
    id: 208,
    name: 'Sweden',
    code: 'SE',
  },
  {
    id: 209,
    name: 'Switzerland',
    code: 'CH',
  },
  {
    id: 210,
    name: 'Syrian Arab Republic',
    code: 'SY',
  },
  {
    id: 211,
    name: 'Taiwan, Province of China',
    code: 'TW',
  },
  {
    id: 212,
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    id: 213,
    name: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  {
    id: 214,
    name: 'Thailand',
    code: 'TH',
  },
  {
    id: 215,
    name: 'Timor-Leste',
    code: 'TL',
  },
  {
    id: 216,
    name: 'Togo',
    code: 'TG',
  },
  {
    id: 217,
    name: 'Tokelau',
    code: 'TK',
  },
  {
    id: 218,
    name: 'Tonga',
    code: 'TO',
  },
  {
    id: 219,
    name: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    id: 220,
    name: 'Tunisia',
    code: 'TN',
  },
  {
    id: 221,
    name: 'Turkey',
    code: 'TR',
  },
  {
    id: 222,
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    id: 223,
    name: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    id: 224,
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    id: 225,
    name: 'Uganda',
    code: 'UG',
  },
  {
    id: 226,
    name: 'Ukraine',
    code: 'UA',
  },
  {
    id: 227,
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    id: 228,
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    id: 229,
    name: 'United States',
    code: 'US',
  },
  {
    id: 230,
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    id: 231,
    name: 'Uruguay',
    code: 'UY',
  },
  {
    id: 232,
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    id: 233,
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    id: 234,
    name: 'Venezuela',
    code: 'VE',
  },
  {
    id: 235,
    name: 'Viet Nam',
    code: 'VN',
  },
  {
    id: 236,
    name: 'Virgin Islands, British',
    code: 'VG',
  },
  {
    id: 237,
    name: 'Virgin Islands, U.S.',
    code: 'VI',
  },
  {
    id: 238,
    name: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    id: 239,
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    id: 240,
    name: 'Yemen',
    code: 'YE',
  },
  {
    id: 241,
    name: 'Zambia',
    code: 'ZM',
  },
  {
    id: 242,
    name: 'Zimbabwe',
    code: 'ZW',
  },
];

// OCCUPATION
export const OCCUPATION = [
  {
    id: 1,
    name: 'Business Professional',
  },
  {
    id: 2,
    name: 'Business Owner/Self-Employed/Entrepreneur',
  },
  {
    id: 3,
    name: 'Medical/Healthcare Professional',
  },
  {
    id: 4,
    name: 'Government/Civil Service',
  },
  {
    id: 5,
    name: 'Educator',
  },
  {
    id: 6,
    name: 'Marketer',
  },
  {
    id: 7,
    name: 'Clerical/Secretary Support',
  },
  {
    id: 8,
    name: 'Customer Service',
  },
  {
    id: 9,
    name: 'Sales/Account Management',
  },
  {
    id: 10,
    name: 'Technology/Engineering',
  },
  {
    id: 11,
    name: 'Social Service',
  },
  {
    id: 12,
    name: 'IT Professional',
  },
  {
    id: 13,
    name: 'Artist',
  },
  {
    id: 14,
    name: 'Media Practitioner',
  },
  {
    id: 15,
    name: 'Digital/Social Media Professional/Content Creator',
  },
  {
    id: 16,
    name: 'Farmer/Fisherman',
  },
  {
    id: 17,
    name: 'Inventor',
  },
  {
    id: 18,
    name: 'Product Developer',
  },
  {
    id: 19,
    name: 'Househelper/Driver',
  },
  {
    id: 20,
    name: 'Accountant/Finance Professional',
  },
  {
    id: 21,
    name: 'Military/Police',
  },
  {
    id: 22,
    name: 'Consultant',
  },
  {
    id: 23,
    name: 'Laborer',
  },
  {
    id: 24,
    name: 'Hotelier/Resort/Retaurant Professional',
  },
  {
    id: 25,
    name: 'Pilot/Flight Attendant',
  },
  {
    id: 26,
    name: 'Author/Publisher',
  },
  {
    id: 27,
    name: 'Athlete',
  },
  {
    id: 28,
    name: 'Chef/Cook/Baker',
  },
  {
    id: 29,
    name: 'Homemaker',
  },
  {
    id: 30,
    name: 'Others',
  },
];

//MONTHS
export const MONTHS = [
  {
    id: '01',
    name: '01',
  },
  {
    id: '02',
    name: '02',
  },
  {
    id: '03',
    name: '03',
  },
  {
    id: '04',
    name: '04',
  },
  {
    id: '05',
    name: '05',
  },
  {
    id: '06',
    name: '06',
  },
  {
    id: '07',
    name: '07',
  },
  {
    id: '08',
    name: '08',
  },
  {
    id: '09',
    name: '09',
  },
  {
    id: '10',
    name: '10',
  },
  {
    id: '11',
    name: '11',
  },
  {
    id: '12',
    name: '12',
  },
];

export const messageErrorForm = {
  maxChars: (max) => [max, `Value must be not greater than ${max} characters`],
  requiredMessage: 'This is a required field.',
  maxMessage: 'This field is larger than 13 characters',
  minMessage: 'Please enter at least 11 characters.',
  emailMessage: 'Email is not valid',
  invalidPhoneNumber:
    'Please input a valid 10 digit phone number.(e.g. 9171234567)',
  invalidName:
    'Please enter a valid name in this field (not including special characters).',
  minLengthPhoneNumber: 'Please enter more or equal than 11 symbols.',
  invalidEmailWithEx:
    'Please enter a valid email address (Ex: johndoe@domain.com).',
  invalidLoginForm: 'Invalid Login or Password',
  disallowedShippingArea: 'We are currently not delivering to this area.',
  loginFailed:
    "Invalid username/password or there's an issue with your account. Please contact us through our Help Center for further assistance.",
  locationLabelMaxSize: `Maximum ${LOCATION_LABEL_SIZE} characters`,
};

export const messages = {
  subscribeProductSuccess:
    "We'll send you an email as soon as the product is available again",
  alreadySubscribed: 'You are already subscribed to this product.',
  reviewSuccess: 'Submit review successful',
  reviewFailed:
    'Something wrong, submit review failed. Please try again or contact support',
};

export const staticFooterData = {
  title: 'Contact us',
  helpLink: { url: '/help-center/contact-us', text: 'Landers Help' },
  storeBusinessHours:
    'Store Business Hours: 9am - 6pm (Balintawak, Otis, Arcovia, Alabang)\n9am - 8pm (Cebu)',
  onlineCSHours: 'Online Customer Service: 7am - 9pm',
  workingDate: 'Monday to Sunday',
  extraNotes: '*excluding Holidays',
  socialLinks: [
    {
      icon: 'https://assets.landers.ph/FB2.png',
      name: 'facebook',
      url: 'https://www.facebook.com/LandersPH/',
    },
    {
      icon: 'https://assets.landers.ph/IG.png',
      name: 'instagram',
      url: 'https://www.instagram.com/landersph/',
    },
    {
      icon: 'https://assets.landers.ph/YT.png',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UC-35-er6ht5owbxt_uStfsw',
    },
  ],
};

export const PHP_TIMEZONE_CODE = 'Asia/Manila';

export const COUNTDOWN_KEY_ENUMS = {
  STARTS_IN: 'startsIn',
  ENDS_IN: 'endsIn',
};
export const COUNTDOWN_KEY_LABELS = {
  [COUNTDOWN_KEY_ENUMS.STARTS_IN]: 'Starts in',
  [COUNTDOWN_KEY_ENUMS.ENDS_IN]: 'Ends in',
};

export const CART_RULE_CODE = {
  PISO_SALE: 'pisosale',
};

export const SPLASH_ADS_LOCATION = {
  ON_LAUNCH: 'on-launch',
  MY_ORDERS: 'my-orders',
  TRACK_ORDERS: 'track-orders',
  SUCCESSFUL_PURCHASE: 'after-a-successful-purchase',
  MC_APPLICATION_PAGE: 'membership-application-page',
};
export const PATHNAME_EXPANDED = [
  URL.account,
  URL.order,
  URL.addressBook,
  URL.myMemberShip,
];

export const GAS_VOUCHER_TOOLTIP =
  'SOLANE, CAPITAL CARE ITEMS AND MEMBERSHIP CARDS ARE NOT INCLUDED TO EARN GAS VOUCHER.';

export const CAPITAL_CARE_VENDOR = 'capitalcare';
export const SOLANE_VENDOR = 'solane';
export const LANDERS_VENDOR = 'landers';
export const GTC_VENDOR = 'gtcaldis';

export const ALLOW_TYPE_UPLOAD = ['video', 'image'];
