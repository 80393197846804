//  Get user info
export const FETCH_USER_INFO = 'landers/Account/FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS =
  'landers/Account/FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILED = 'landers/Account/FETCH_USER_INFO_FAILED';

// Get all orders
export const GET_All_ORDERS = 'landers/Account/GET_All_ORDERS';
export const GET_All_ORDERS_SUCCESS = 'landers/Account/GET_All_ORDERS_SUCCESS';
export const GET_All_ORDERS_FAILED = 'landers/Account/GET_All_ORDERS_FAILED';

export const GET_CURRENT_ORDERS = 'landers/Account/GET_CURRENT_ORDERS';
export const GET_CURRENT_ORDERS_SUCCESS =
  'landers/Account/GET_CURRENT_ORDERS_SUCCESS';
export const GET_CURRENT_ORDERS_FAILED =
  'landers/Account/GET_CURRENT_ORDERS_FAILED';

// GET COUNT
export const GET_PROCESSING_ORDERS =
  'landers/Account/GET_ALL_PROCESSING_ORDERS_SUCCESS';
export const GET_PROCESSING_ORDERS_SUCCESS =
  'landers/Account/GET_ALL_PROCESSING_ORDERS_SUCCESS';
export const GET_PROCESSING_ORDERS_FAILED =
  'landers/Account/GET_ALL_PROCESSING_ORDERS_SUCCESS';

// GET COUNT
export const GET_COUNT_PROCESSING_ORDERS =
  'landers/Account/GET_COUNT_PROCESSING_ORDERS';
export const GET_COUNT_PROCESSING_ORDERS_SUCCESS =
  'landers/Account/GET_COUNT_PROCESSING_ORDERS_SUCCESS';
export const GET_COUNT_PROCESSING_ORDERS_FAILED =
  'landers/Account/GET_COUNT_PROCESSING_ORDERS_FAILED';

export const SET_POPUP_TRACKER_DRAWER =
  'landers/Account/SET_POPUP_TRACKER_DRAWER';

// Create a new address
export const CREATE_ADDRESS = 'landers/Address/CREATE_ADDRESS';
export const CREATE_ADDRESS_SUCCESS = 'landers/Address/CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILED = 'landers/Address/CREATE_ADDRESS_FAILED';
export const CLEAR_STATUS_EDITABLE_ADDRESS =
  'landers/Address/CLEAR_STATUS_EDITABLE_ADDRESS';

// Update an address
export const UPDATE_ADDRESS = 'landers/Address/UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SUCCESS = 'landers/Address/UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILED = 'landers/Address/UPDATE_ADDRESS_FAILED';

export const UPDATE_USER_INFO = 'landers/Address/UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS =
  'landers/Address/UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILED =
  'landers/Address/UPDATE_USER_INFO_FAILED';

export const UPDATE_EMAIL = 'landers/Address/UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'landers/Address/UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILED = 'landers/Address/UPDATE_EMAIL_FAILED';

export const UPDATE_PASSWORD = 'landers/Address/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS =
  'landers/Address/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'landers/Address/UPDATE_PASSWORD_FAILED';

export const SUBSCRIPTIONS_TYPE = {
  PRICE: 'PRICE',
  STOCK: 'STOCK',
};

export const GET_PRICE_SUBSCRIPTIONS =
  'landers/Account/GET_PRICE_SUBSCRIPTIONS';
export const GET_PRICE_SUBSCRIPTIONS_SUCCESS =
  'landers/Account/GET_PRICE_SUBSCRIPTIONS_SUCCESS';
export const GET_PRICE_SUBSCRIPTIONS_FAILED =
  'landers/Account/GET_PRICE_SUBSCRIPTIONS_FAILED';

export const DELETE_PRICE_SUBSCRIPTIONS =
  'landers/Account/DELETE_PRICE_SUBSCRIPTIONS';
export const DELETE_PRICE_SUBSCRIPTIONS_SUCCESS =
  'landers/Account/DELETE_PRICE_SUBSCRIPTIONS_SUCCESS';
export const DELETE_PRICE_SUBSCRIPTIONS_FAILED =
  'landers/Account/DELETE_SUBSCRIPTIONS_FAILED';

export const GET_STOCK_SUBSCRIPTIONS =
  'landers/Account/GET_STOCK_SUBSCRIPTIONS';
export const GET_STOCK_SUBSCRIPTIONS_SUCCESS =
  'landers/Account/GET_STOCK_SUBSCRIPTIONS_SUCCESS';
export const GET_STOCK_SUBSCRIPTIONS_FAILED =
  'landers/Account/GET_STOCK_SUBSCRIPTIONS_FAILED';

export const DELETE_STOCK_SUBSCRIPTIONS =
  'landers/Account/DELETE_STOCK_SUBSCRIPTIONS';
export const DELETE_STOCK_SUBSCRIPTIONS_SUCCESS =
  'landers/Account/DELETE_STOCK_SUBSCRIPTIONS_SUCCESS';
export const DELETE_STOCK_SUBSCRIPTIONS_FAILED =
  'landers/Account/DELETE_STOCK_SUBSCRIPTIONS_FAILED';

// PRODUCT REVIEW
export const LOAD_PRODUCT_REVIEW = 'landers/Account/LOAD_PRODUCT_REVIEW';
export const SAVE_PRODUCT_REVIEW = 'landers/Account/SAVE_PRODUCT_REVIEW';

// PRODUCT REVIEW
export const LOAD_LOYALTY_REBATES = 'landers/Account/LOAD_LOYALTY_REBATES';
export const LOAD_LOYALTY_REBATES_TRANSACTION =
  'landers/Account/LOAD_LOYALTY_REBATES_TRANSACTION';

export const GET_REBATE_TRANSACTIONS =
  'landers/Account/GET_REBATE_TRANSACTIONS';

export const GET_REBATE_UPDATE = 'landers/Account/GET_REBATE_UPDATE';
export const GET_REBATE_UPDATE_SUCCESS =
  'landers/Account/GET_REBATE_UPDATE_SUCCESS';
export const SEEN_REBATE_UPDATE = 'landers/Account/SEEN_REBATE_UPDATE';
