/*
 *
 * Modals constants
 *
 */

// Show/Hide delivery location modal
export const SHOW_DELIVERY_LOCATION = 'landers/Modals/SHOW_DELIVERY_LOCATION';

export const HIDE_DELIVERY_LOCATION = 'landers/Modals/HIDE_DELIVERY_LOCATION';

// Show/Hide product detail modal
export const SHOW_PRODUCT_DETAIL = 'landers/Modals/SHOW_PRODUCT_DETAIL';
export const SHOW_PRODUCT_DETAIL_DONE =
  'landers/Modals/SHOW_PRODUCT_DETAIL_DONE';
export const HIDE_PRODUCT_DETAIL = 'landers/Modals/HIDE_PRODUCT_DETAIL';

// Show/Hide login modal
export const SHOW_LOGIN = 'landers/Modals/SHOW_LOGIN';
export const HIDE_LOGIN = 'landers/Modals/HIDE_LOGIN';

// Show/Hide forgot password modal
export const SHOW_FORGOT_PASSWORD = 'landers/Modals/SHOW_FORGOT_PASSWORD';
export const HIDE_FORGOT_PASSWORD = 'landers/Modals/HIDE_FORGOT_PASSWORD';

// Show/Hide add/update address modal
export const SHOW_ADDUPDATE_ADDRESS = 'landers/Modals/SHOW_ADDUPDATE_ADDRESS';

export const HIDE_ADDUPDATE_ADDRESS = 'landers/Modals/HIDE_ADDUPDATE_ADDRESS';

// Show/Hide invalid cart items modal
export const SHOW_INVALID_CARTITEMS = 'landers/Modals/SHOW_INVALID_CARTITEMS';
export const HIDE_INVALID_CARTITEMS = 'landers/Modals/HIDE_INVALID_CARTITEMS';
// Show/Hide invalid cart item qty modal
export const SHOW_MAX_CARTITEMS_QTY = 'landers/Modals/SHOW_MAX_CARTITEMS_QTY';
export const HIDE_MAX_CARTITEMS_QTY = 'landers/Modals/HIDE_MAX_CARTITEMS_QTY';

export const SHOW_REQUEST_ADD_MEMBERSHIP =
  'landers/Modals/SHOW_REQUEST_ADD_MEMBERSHIP';
export const HIDE_REQUEST_ADD_MEMBERSHIP =
  'landers/Modals/HIDE_REQUEST_ADD_MEMBERSHIP';

export const SHOW_CART_NOTIFICATION_MODAL =
  'landers/Modals/SHOW_CART_NOTIFICATION_MODAL';
export const HIDE_CART_NOTIFICATION_MODAL =
  'landers/Modals/HIDE_CART_NOTIFICATION_MODAL';

export const SHOW_REMOVE_ALL_CART_ITEMS =
  'landers/Modals/SHOW_REMOVE_ALL_CART_ITEMS';
export const HIDE_REMOVE_ALL_CART_ITEMS =
  'landers/Modals/HIDE_REMOVE_ALL_CART_ITEMS';

export const SHOW_REMOVE_CART_ITEMS = 'landers/Modals/SHOW_REMOVE_CART_ITEMS';
export const HIDE_REMOVE_CART_ITEMS = 'landers/Modals/HIDE_REMOVE_CART_ITEMS';

export const SHOW_ALERT = 'landers/Modals/SHOW_ALERT';
export const HIDE_ALERT = 'landers/Modals/HIDE_ALERT';

//Tab sign up
export const CHANGE_TAB_SIGN_UP = 'landers/Authentication/TabSignUp';

export const SHOW_MEMBERSHIP_MODAL = 'landers/Modals/SHOW_MEMBERSHIP_MODAL';
export const HIDE_MEMBERSHIP_MODAL = 'landers/Modals/HIDE_MEMBERSHIP_MODAL';

// Show SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED
export const SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED =
  'landers -> modals -> show Modal Inform Membership Is Expired';
export const HIDE_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED =
  'landers -> modals -> hide Modal Inform Membership Is Expired';

// Show Shopping list
export const SHOW_SHARE_SHOPPING_LIST =
  'landers/Modals/SHOW_SHARE_SHOPPING_LIST';
export const SHOW_CREATE_SHOPPING_LIST =
  'landers/Modals/SHOW_CREATE_SHOPPING_LIST';
export const SHOW_EDIT_SHOPPING_LIST = 'landers/Modals/SHOW_EDIT_SHOPPING_LIST';
export const SHOW_ADD_PRODUCT_TO_SHOPPING_LIST =
  'landers/Modals/SHOW_ADD_PRODUCT_TO_SHOPPING_LIST';

export const HIDE_MODAL_SHOPPING_LIST =
  'landers/Modals/HIDE_MODAL_SHOPPING_LIST';

export const SHOW_MODAL_FAQ_SUCCESS = 'landers/Modals/SHOW_MODAL_FAQ_SUCCESS';
export const SHOW_MODAL_FAQ_FAILED = 'landers/Modals/SHOW_MODAL_FAQ_FAILED';
export const HIDE_MODAL_FAQ = 'landers/Modals/HIDE_MODAL_FAQ';

export const SHOW_PICKUP_CONFIRMATION =
  'landers/Modals/SHOW_PICKUP_CONFIRMATION';
export const HIDE_PICKUP_CONFIRMATION =
  'landers/Modals/HIDE_PICKUP_CONFIRMATION';

export const SHOW_APP_PROMOTION_MODAL =
  'landers/Modals/SHOW_APP_PROMOTION_MODAL';
export const HIDE_APP_PROMOTION_MODAL =
  'landers/Modals/HIDE_APP_PROMOTION_MODAL';

export const SHOW_SPLASH_AD_MODAL = 'landers/Modals/SHOW_SPLASH_AD_MODAL';
export const HIDE_SPLASH_AD_MODAL = 'landers/Modals/HIDE_SPLASH_AD_MODAL';

export const SHOW_NEW_MC_EXPIRED_MODAL =
  'landers/Modals/SHOW_NEW_MC_EXPIRED_MODAL';
export const HIDE_NEW_MC_EXPIRED_MODAL =
  'landers/Modals/HIDE_NEW_MC_EXPIRED_MODAL';

export const SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL =
  'landers/Modals/SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL';
export const HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL =
  'landers/Modals/HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL';

export const SHOW_DELIVERY_ADDRESS_MODAL =
  'landers/Modals/SHOW_DELIVERY_ADDRESS_MODAL';
export const HIDE_DELIVERY_ADDRESS_MODAL =
  'landers/Modals/HIDE_DELIVERY_ADDRESS_MODAL';

export const SHOW_SUBSCRIBE_SUCCESS_MODAL =
  'landers/Modals/SHOW_SUBSCRIBE_SUCCESS_MODAL';
export const HIDE_SUBSCRIBE_SUCCESS_MODAL =
  'landers/Modals/HIDE_SUBSCRIBE_SUCCESS_MODAL';

export const SHOW_PAYMENT_CARD_MODAL = 'landers/Modals/SHOW_PAYMENT_CARD_MODAL';
export const HIDE_PAYMENT_CARD_MODAL = 'landers/Modals/HIDE_PAYMENT_CARD_MODAL';

export const SHOW_CARD_SAVED_MODAL = 'landers/Modals/SHOW_CARD_SAVED_MODAL';
export const HIDE_CARD_SAVED_MODAL = 'landers/Modals/HIDE_CARD_SAVED_MODAL';
export const SHOW_CARD_SAVED_SUCCESS = 'landers/Modals/SHOW_CARD_SAVED_SUCCESS';
export const SHOW_REMOVE_CARD_SAVED_MODAL =
  'landers/Modals/SHOW_REMOVE_CARD_SAVED_MODAL';
export const HIDE_REMOVE_CARD_SAVED_MODAL =
  'landers/Modals/HIDE_REMOVE_CARD_SAVED_MODAL';

export const SHOW_CVC_TOOLTIP_MODAL = 'landers/Modals/SHOW_CVC_TOOLTIP_MODAL';
export const HIDE_CVC_TOOLTIP_MODAL = 'landers/Modals/HIDE_CVC_TOOLTIP_MODAL';

export const SHOW_ADD_CARD_MODAL = 'landers/Modals/SHOW_ADD_CARD_MODAL';
export const HIDE_ADD_CARD_MODAL = 'landers/Modals/HIDE_ADD_CARD_MODAL';
export const SHOW_ADD_MORE_ITEM_MODAL =
  'landers/Modals/SHOW_ADD_MORE_ITEM_MODAL';
export const LINK_MEMBERSHIP_SHOW_SUPPORT_MESSAGE =
  'landers/Modals/LINK_MEMBERSHIP_SHOW_SUPPORT_MESSAGE';
export const SHOW_ERROR_MESSGE_MODAL = 'landers/Modals/SHOW_ERROR_MESSGE_MODAL';
export const SHOW_DROPSHIP_WARNING_MODAL =
  'landers/Modals/SHOW_DROPSHIP_WARNING_MODAL';

export const SHOW_REORDER_MODAL = 'landers/Modals/SHOW_REORDER_MODAL';
export const SHOW_REORDER_MODAL_SUCCESS =
  'landers/Modals/SHOW_REORDER_MODAL_SUCCESS';
export const HIDE_REORDER_MODAL = 'landers/Modals/HIDE_REORDER_MODAL';
export const SET_REORDER_PRODUCT_DETAILS =
  'landers/Modals/SET_REORDER_PRODUCT_DETAILS';

export const SET_EXPIRED_MC_MODAL = 'landers/Modals/SET_EXPIRED_MC_MODAL';

export const CONFIRM_PICKUP_MODAL = 'landers/Modals/CONFIRM_PICKUP_MODAL';
export const AFTER_PICKUP_MODAL_CLOSED =
  'landers/Modals/AFTER_PICKUP_MODAL_CLOSED';

export const SET_NET_PROMOTERS_SCORE_SUCCESS =
  'landers/Modals/SET_NET_PROMOTERS_SCORE_SUCCESS';
export const SHOW_PRICE_FILTER_MODAL = 'landers/Modals/SHOW_PRICE_FILTER_MODAL';
export const HIDE_PRICE_FILTER_MODAL = 'landers/Modals/HIDE_PRICE_FILTER_MODAL';

export const SHOW_RAFFLE_STEPS_MODAL = 'landers/Modals/SHOW_RAFFLE_STEPS_MODAL';
export const HIDE_RAFFLE_STEPS_MODAL = 'landers/Modals/HIDE_RAFFLE_STEPS_MODAL';

export const SHOW_PREMIUM_REQUIRED_MODAL =
  'landers/Modals/SHOW_PREMIUM_REQUIRED_MODAL';
export const HIDE_PREMIUM_REQUIRED_MODAL =
  'landers/Modals/HIDE_PREMIUM_REQUIRED_MODAL';

export const SHOW_LOYALTY_REBATE_MODAL =
  'landers/Modals/SHOW_LOYALTY_REBATE_MODAL';
export const HIDE_LOYALTY_REBATE_MODAL =
  'landers/Modals/HIDE_LOYALTY_REBATE_MODAL';

export const SHOW_REBATE_MODULE_MODAL =
  'landers/Modals/SHOW_REBATE_MODULE_MODAL';
export const HIDE_REBATE_MODULE_MODAL =
  'landers/Modals/HIDE_REBATE_MODULE_MODAL';
export const SHOW_EXECUTIVE_MODAL = 'landers/Modals/SHOW_EXECUTIVE_MODAL';
export const HIDE_EXECUTIVE_MODAL = 'landers/Modals/HIDE_EXECUTIVE_MODAL';
export const SHOW_PRODUCTB2P1_MODAL = 'landers/Modals/SHOW_PRODUCTB2P1_MODAL';
export const HIDE_PRODUCTB2P1_MODAL = 'landers/Modals/HIDE_PRODUCTB2P1_MODAL';

export const SHOW_EXECUTIVE_CONFIRMATION_MODAL =
  'landers/Modals/SHOW_EXECUTIVE_CONFIRMATION_MODAL';
export const HIDE_EXECUTIVE_CONFIRMATION_MODAL =
  'landers/Modals/HIDE_EXECUTIVE_CONFIRMATION_MODAL';
