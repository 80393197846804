import { coreApi as api } from 'api/coreApi';
import { seenRebateUpdate } from 'containers/Account/rtk/slice';
import qs from 'qs';
import {
  RebateDetailsResponseTypes,
  RequestRebateTransactionProp,
  TransactionOrderRawResponse,
} from './types';

const addTagTypes = ['RebateDetails'];

export const accountApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRebateDetails: builder.query({
        query: () => {
          return {
            url: 'transaction/rebates',
            method: 'GET',
          };
        },
        providesTags: addTagTypes,
        async onQueryStarted(params, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            // dispatch(seenRebateUpdate(''));
          } catch (error) {}
        },
      }),
      getUnseenRebates: builder.query({
        query: () => {
          return {
            url: 'transaction/rebates/not-seen',
            method: 'GET',
          };
        },
      }),
      onSeenRebateUpdate: builder.mutation({
        query: ({ rebateId }) => ({
          url: `transaction/rebates/seen`,
          method: 'PUT',
          body: {
            // if rebateId is empty string, it will update all rebate transactions status into seen,
            // if rebateId has value, it will update status into seen for that specific transaction
            rebateId,
          },
        }),
      }),
      getRebateTransaction: builder.query({
        query: ({
          transactionType = '',
          orderBy = 'created_at DESC',
          orderType = '',
          page = 1,
          limit = 15,
        }) => {
          let requestUrl = 'transaction/rebates/history?';
          let params = {
            ...(transactionType && { transactionType }),
            ...(orderType && { orderType }),
            orderBy,
            page,
            limit,
          };
          return {
            url: `${requestUrl}${qs.stringify(params, {
              encode: false,
            })}`,
            method: 'GET',
          };
        },
        transformResponse: (response) => {
          return response.data;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return JSON.stringify({
            endpointName,
            transactionType: queryArgs.transactionType,
            orderType: queryArgs.orderType,
          });
        },
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page || currentArg?.page === 1
          );
        },
        merge: (currentCache, newItems, otherArgs) => {
          const { page = 1 } = otherArgs.arg;

          if (page > 1) {
            return {
              data: [...currentCache.data, ...newItems.data],
              total_count: newItems.total_count,
            };
          }

          return newItems;
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetRebateDetailsQuery,
  useGetUnseenRebatesQuery,
  useGetRebateTransactionQuery,
} = accountApi;
export default accountApi;
