import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './constants';

const renewMayaSlice = createSlice({
  name: 'renewMayaSlice',
  initialState: INITIAL_STATE,
  reducers: {
    onShowMayaApply(state) {
      state.isShowMayaApply = true;
    },
    onHideMayaApply(state) {
      state.isShowMayaApply = false;
    },
    setMembershipSF(state, action) {
      state.membershipSfData = action.payload;
    },
    setShowMayaRenew(state, action) {
      state.isShowMayaRenew = action.payload.isShowMayaRenew;
      state.selectedMembershipProducts =
        action.payload.selectedMembershipProducts;
    },
    setShowMayaXLandersModal(state, action) {
      state.isShowMayaXLanders = action.payload;
    },
    setShowMayaCompleteOrder(state, action) {
      state.isShowMayaCompleteOrder = action.payload;
    },
    onShowMayaXLandersModal(state) {
      state.isShowMayaXLanders = true;
    },
    onShowMayaRenew(state, action) {
      state.isShowExpireMC = false;
    },
    onShowMayaCompleteOrder(state) {
      state.isShowMayaCompleteOrder = true;
    },
    onHideMayaCompleteOrder(state) {
      state.isShowMayaCompleteOrder = false;
    },
    onShowCompleteMaya(state) {
      state.isShowCompleteMaya = true;
    },
    setShowCompleteMaya(state, action) {
      state.isShowCompleteMaya = action.payload;
    },
    setIsMayaCCOnly(state, action) {
      state.isMayaCCOnly = action.payload;
    },
    onSubmitMayaInfoToSF(state, action) {},
    setVerbiage(state, action) {
      state.verbiage = action.payload;
    },
    onShowMayaXLandersOrCompleteModal(state) {},
    setCloseMayaCCOnlyModal(state, action) {
      state.isShowMayaRenew = action.payload?.isShowMayaRenew;
      state.isMayaCCOnly = action.payload?.isMayaCCOnly;
    },
    setShowOnlineAccountRequired(state, action) {
      state.isShowOnlineAccountRequired = action.payload;
    },
    setIsLoginWithMayaCC(state, action) {
      state.isLoginWithMayaCC = action.payload;
    },
    onShowSnackBar(state, action) {
      state.isShowSnackbar = true;
      state.snackbarDetails = action.payload;
    },
    setShowSnackBar(state, action) {
      state.isShowSnackbar = action.payload;
    },
    setShowMembershipLinkFormModal(state, action) {
      state.isShowMembershipLinkFormModal = action.payload;
    },
    setMayaCCButtonOption(state, action) {
      state.mayaCCButtonOption = action.payload;
    },
    onShowLoginWithMayaCCSuccess(state) {},
    setShowLinkMembershipCard(state, action) {
      state.isShowLinkMembershipCard = action.payload;
    },
    onShowExpireMC(state, action) {
      state.isShowExpireMC = true;
      state.expireMCAlert = action.payload;
    },
    setShowExpireMC(state, action) {
      state.isShowExpireMC = action.payload;
    },
    setMembershipExpiryData(state, action) {
      state.membershipExpiryData = action.payload;
    },
    setMembershipEmailExistNotif(state, action) {
      state.isShowMembershipEmailExistNotif = action.payload;
    },
  },
});

export const {
  onShowMayaApply,
  onHideMayaApply,
  setMembershipSF,
  setShowMayaRenew,
  setShowMayaXLandersModal,
  onShowMayaXLandersModal,
  onShowMayaRenew,
  onShowCompleteMaya,
  setShowCompleteMaya,
  setShowMayaCompleteOrder,
  onShowMayaCompleteOrder,
  onHideMayaCompleteOrder,
  setIsMayaCCOnly,
  onSubmitMayaInfoToSF,
  setVerbiage,
  onShowMayaXLandersOrCompleteModal,
  setCloseMayaCCOnlyModal,
  setShowOnlineAccountRequired,
  setIsLoginWithMayaCC,
  setShowSnackBar,
  onShowSnackBar,
  onShowLoginWithMayaCCSuccess,
  setShowLinkMembershipCard,
  setShowMembershipLinkFormModal,
  setMayaCCButtonOption,
  onShowExpireMC,
  setShowExpireMC,
  setMembershipExpiryData,
  setMembershipEmailExistNotif,
} = renewMayaSlice.actions;

export default renewMayaSlice.reducer;
