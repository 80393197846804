import { createSelector } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './constants';

const selectRebateDomain = (state: any) => state.rebateSlice || INITIAL_STATE;

export const makeSelectGetRebateHasUpdate = () =>
  createSelector(selectRebateDomain, (subState) => subState.hasUpdate);
export const makeSelectGetSeenRebateUpdate = () =>
  createSelector(selectRebateDomain, (subState) => subState.rebateId);
