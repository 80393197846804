import { productToCartItem } from 'containers/Checkout/helpers/mapper';
import {
  clone,
  cloneDeep,
  each,
  findIndex,
  forEach,
  get,
  map,
  set,
} from 'lodash';
import * as productUtils from 'utils/product';
import { getCartData, setCartData } from 'utils/validate';
import { isSpecialInstruction } from 'utils/products';
import { getSpecialInstructionValue } from 'utils/cart';

const productInCart = (cartItems, product) => {
  const { sku, product_type } = product;
  if (product_type === 'customer_membership') {
    // previously we are comparing this via item_id but since it is causing a bug changed it to sku
    // item.item_id === item_id
    return findIndex(cartItems, (item) => item.sku === sku);
  }
  return findIndex(cartItems, (item) => item.sku === sku);
};

const calculateCart = (cartData) => {
  let subtotal = 0;

  each(cartData.items, (cartItem) => {
    let total = cartItem.price * cartItem.qty;
    cartItem.base_row_total = total;
    cartItem.base_row_saved = productUtils.getSavedValue(cartItem);
    subtotal += total;
  });

  cartData.subtotal = subtotal;

  setCartData(cartData);

  return cartData;
};

const syncCartClient = (cartData, products) => {
  if (!cartData.prev_promotion_items) {
    cartData.prev_promotion_items = map(cartData.promotion_items, clone);
  }

  const newCart = cloneDeep(cartData);
  const { items, promotion_items } = clone(newCart);

  forEach(items, (item, index) => {
    // carry over the special_instructions for the next sync-cart run
    if (isSpecialInstruction(item?.extension_attributes?.product_data)) {
      let special_instructions = getSpecialInstructionValue(
        item?.extension_attributes?.product_data?.options,
      );
      set(items, `[${index}].special_instructions`, special_instructions);
    }
  });

  each(products, (product) => {
    const { data, qty } = product;
    let special_instructions = '';

    if (product?.special_instructions) {
      special_instructions = product.special_instructions;
    }

    const syncProduct = { ...data, qty, special_instructions };
    const cartItemIndex = productInCart(items, product.data);

    // Product already existed in cart
    if (cartItemIndex > -1) {
      set(items, `[${cartItemIndex}].qty`, qty);
      set(
        items,
        `[${cartItemIndex}].special_instructions`,
        special_instructions,
      );
    } else {
      items.push(productToCartItem(syncProduct));
    }

    if (promotion_items) {
      const cartItemPromotionIndex = productInCart(
        promotion_items,
        product.data,
      );

      if (cartItemPromotionIndex > -1) {
        const promotionItem = get(
          promotion_items,
          `[${cartItemPromotionIndex}]`,
        );

        set(
          promotion_items,
          `[${cartItemPromotionIndex}].qty`,
          qty - get(promotionItem, 'promotion.promotion_qty', 0),
        );
      }
    }
  });

  newCart.items = map(items, clone);
  newCart.promotion_items = map(promotion_items, clone);

  return calculateCart(newCart);
};

const removeAllProductsInCart = (cartData) => {
  const newCart = {
    items: [],
    prev_promotion_items: map(cartData.promotion_items, clone),
    promotion_items: [],
  };
  return calculateCart(newCart);
};

/**
 * Get items existing in cart after remove promotion items
 */
const getLeftItemsInCartPromotion = (cartItemPromotion) => {
  const cartData = clone(getCartData());
  const cartItems = map(cartData.items, clone);
  const buyItems = cartItemPromotion.buy;
  const getItems = cartItemPromotion.get;

  each(buyItems, (buyItem) => {
    const inCartIndex = findIndex(
      cartItems,
      (cartItem) => cartItem.sku === buyItem.sku,
    );

    if (inCartIndex > -1) {
      const currentQty = get(cartItems, `[${inCartIndex}].qty`, 0);
      const promotionQty = get(buyItem, 'promotion.promotion_qty', 0);

      set(cartItems, `[${inCartIndex}].qty`, currentQty - promotionQty);
    }
  });

  each(getItems, (getItem) => {
    const inCartIndex = findIndex(
      cartItems,
      (cartItem) => cartItem.sku === getItem.sku,
    );

    if (inCartIndex > -1) {
      const currentQty = get(cartItems, `[${inCartIndex}].qty`, 0);
      const promotionQty = get(getItem, 'promotion.promotion_qty', 0);
      set(cartItems, `[${inCartIndex}].qty`, currentQty - promotionQty);
    }
  });

  return cartItems;
};

const cartHelper = {
  syncCartClient,
  removeAllProductsInCart,
  getLeftItemsInCartPromotion,
  calculateCart,
};

export default cartHelper;
