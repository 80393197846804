import { createAction } from '@reduxjs/toolkit';
import * as constants from '../constants';
import { createSlice } from '@reduxjs/toolkit';

import { InitialState } from './type';
import { INITIAL_STATE } from './constants';
// export const getRebateTransaction = createAction(
//   constants.GET_REBATE_TRANSACTIONS,
// );

// export const getRebateUpdate = createAction(
//   constants.GET_REBATE_UPDATE,
//   function prepare(hasUpdate: number) {
//     return {
//       payload: hasUpdate,
//     };
//   },
// );
// export const getRebateUpdateSuccess = createAction(
//   constants.GET_REBATE_UPDATE_SUCCESS,
//   function prepare(hasUpdate: boolean) {
//     return {
//       payload: hasUpdate,
//     };
//   },
// );

// export const seenRebateUpdate = createAction(
//   constants.SEEN_REBATE_UPDATE,
//   function prepare(rebateId: string) {
//     return {
//       payload: rebateId,
//     };
//   },
// );

const slice = createSlice({
  name: 'rebateSlice',
  initialState: INITIAL_STATE,
  reducers: {
    getRebateUpdate: (state: InitialState, action) => {},
    getRebateUpdateSuccess: (state: InitialState, action) => {
      state.hasUpdate = action.payload;
    },
    seenRebateUpdate: (state: InitialState, action) => {
      state.rebateId = action.payload;
    },
  },
});

export const { getRebateUpdate, getRebateUpdateSuccess, seenRebateUpdate } =
  slice.actions;

export default slice.reducer;
