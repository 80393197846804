// eslint-disable-next-line
import { produce } from 'immer';
import localStorage from 'redux-persist/lib/storage';
import * as constants from './constants';
import { get } from 'lodash';
import { persistReducer } from 'redux-persist';
import { LOGOUT } from 'containers/Landers/constants';

const initialTotalExpiredMC = {
  numberOfDays: 0,
  isShow: false,
  type: '',
  expiredDate: '',
  isAlreadyShown: false,
};

export const initialState = {
  showDeliveryLocation: false,
  showProductDetail: false,
  showLogin: false,
  showForgotPassword: false,
  showAddUpdateAddress: false,
  showInvalidCartItems: false,
  showMaxCartItemsQty: false,
  showRequestAddMembership: false,
  showCartNotification: false,
  showRemoveAllCartItems: false,
  showRemoveCartItems: false,
  isSignUp: true,
  showMembership: null,
  showShoppingList: false,
  faqForm: false,
  addedProduct: {},
  showPickUpConfirmation: false,
  showAppPromotion: false,
  showSplashAd: false,
  showNewMCExpired: false,
  membershipPayload: null,
  showSubscribeSuccess: false,
  tracking: {},
  currentPopup: null,
  showNetPromotersScoreSurvey: false,
  netPromotersScorePayload: null,
  showDeliveryAddress: false,
  showPaymentCard: {},
  showCardSaved: false,
  showCardSavedSuccess: false,
  showRemoveCardSaved: {},
  showCVCTooltip: false,
  showAddMoreItemModal: false,
  showSupportMessage: false,
  showErrorMessage: false,
  showDropshipWarningMessage: false,
  showReorderModal: false,
  reorderDetails: [],
  tooltipExpiredMC: initialTotalExpiredMC,
  specialInstructionValue: '',
  netPromotersScoreSuccess: false,
  priceFilterDetails: null,
  showRaffleStepsModal: null,
  showPremiumRequiredModal: false,
  showProductB2P1Modal: false,
  showLoyaltyRebateModal: false,
  showRebateModuleModal: false,
  showExecModal: false,
  showExecutiveConfirmationModal: false,
};

const persistConfig = {
  key: 'modal',
  storage: localStorage,
  whitelist: ['showCardSavedSuccess'],
  debounce: 3000,
};

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // SHOW_DELIVERY_LOCATION
      case constants.SHOW_DELIVERY_LOCATION:
        newState.showDeliveryLocation = true;
        newState.addedProduct = action.addedProduct;
        break;
      case constants.HIDE_DELIVERY_LOCATION:
        newState.showDeliveryLocation = false;
        newState.addedProduct = {};
        break;

      // SHOW_PRODUCT_DETAIL
      case constants.SHOW_PRODUCT_DETAIL:
        newState.tracking = action.tracking;
        newState.specialInstructionValue =
          action.tracking?.specialInstructionValue || '';
        break;
      case constants.SHOW_PRODUCT_DETAIL_DONE:
        newState.showProductDetail = true;
        break;
      case constants.HIDE_PRODUCT_DETAIL:
        newState.tracking = {};
        newState.showProductDetail = false;
        newState.specialInstructionValue = '';
        break;

      // SHOW_LOGIN
      case constants.SHOW_LOGIN:
        newState.showLogin = action.params;
        break;
      case constants.HIDE_LOGIN:
        newState.showLogin = false;
        break;

      // SHOW_FORGOT_PASSWORD
      case constants.SHOW_FORGOT_PASSWORD:
        newState.showForgotPassword = action.params;
        break;
      case constants.HIDE_FORGOT_PASSWORD:
        newState.showForgotPassword = false;
        break;

      // SHOW_ADDUPDATE_ADDRESS
      case constants.SHOW_ADDUPDATE_ADDRESS:
        newState.showAddUpdateAddress = action.initAddress;
        break;
      case constants.HIDE_ADDUPDATE_ADDRESS:
        newState.showAddUpdateAddress = false;
        break;

      // SHOW_INVALID_CARTITEMS
      case constants.SHOW_INVALID_CARTITEMS:
        if (action.invalidSkus) {
          newState.showInvalidCartItems = action.invalidSkus;
        } else {
          newState.showInvalidCartItems = true;
        }
        break;
      case constants.HIDE_INVALID_CARTITEMS:
        newState.showInvalidCartItems = false;
        break;
      //SHOW MAX CART ITEMS QTY
      case constants.SHOW_MAX_CARTITEMS_QTY:
        if (action.invalidSkus) {
          newState.showMaxCartItemsQty = action.invalidSkus;
        }
        break;
      case constants.HIDE_MAX_CARTITEMS_QTY:
        newState.showMaxCartItemsQty = false;
        break;

      // REQUEST ADD MEMBERSHIP MODAL
      case constants.SHOW_REQUEST_ADD_MEMBERSHIP:
        newState.showRequestAddMembership = true;
        break;
      case constants.HIDE_REQUEST_ADD_MEMBERSHIP:
        newState.showRequestAddMembership = false;
        break;

      // REQUEST ADD MEMBERSHIP MODAL
      case constants.SHOW_CART_NOTIFICATION_MODAL:
        newState.showCartNotification = action.info;
        break;
      case constants.HIDE_CART_NOTIFICATION_MODAL:
        newState.showCartNotification = false;
        break;

      // SHOW_REMOVE_ALL_CART_ITEMS
      case constants.SHOW_REMOVE_ALL_CART_ITEMS:
        newState.showRemoveAllCartItems = true;
        break;
      case constants.HIDE_REMOVE_ALL_CART_ITEMS:
        newState.showRemoveAllCartItems = false;
        break;

      // SHOW_REMOVE_CART_ITEMS
      case constants.SHOW_REMOVE_CART_ITEMS:
        newState.showRemoveCartItems = action.cartItems;
        break;
      case constants.HIDE_REMOVE_CART_ITEMS:
        newState.showRemoveCartItems = false;
        break;

      // SHOW_ALERT
      case constants.SHOW_ALERT:
        newState.showAlert = action.alertOptions;
        break;
      case constants.HIDE_ALERT:
        newState.showAlert = false;
        break;

      case constants.CHANGE_TAB_SIGN_UP:
        newState.isSignUp = action.payload;
        break;

      // REQUEST SHOW MEMBERSHIP MODAL
      case constants.SHOW_MEMBERSHIP_MODAL:
        newState.showMembership = action.params;
        break;
      case constants.HIDE_MEMBERSHIP_MODAL:
        newState.showMembership = null;
        break;
      // SHOW MODAL INFORM MEMBERSHIP IS EXPIRED
      case constants.SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED:
        newState.showInformMembershipIsExpiredModal = true;
        break;
      case constants.HIDE_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED:
        newState.showInformMembershipIsExpiredModal = false;
        break;

      // SHOPPING_LIST
      case constants.SHOW_SHARE_SHOPPING_LIST:
        newState.showShoppingList = action.params;
        break;

      case constants.SHOW_CREATE_SHOPPING_LIST:
        newState.showShoppingList = action.params;
        break;

      case constants.SHOW_EDIT_SHOPPING_LIST:
        newState.showShoppingList = action.params;
        break;

      case constants.SHOW_ADD_PRODUCT_TO_SHOPPING_LIST:
        newState.showShoppingList = action.params;
        break;

      case constants.HIDE_MODAL_SHOPPING_LIST:
        newState.showShoppingList = false;
        break;

      case constants.SHOW_MODAL_FAQ_SUCCESS:
        newState.faqForm = {
          success: true,
          error: false,
          errorMessage: '',
        };
        break;
      case constants.SHOW_MODAL_FAQ_FAILED:
        newState.faqForm = {
          success: false,
          error: true,
          errorMessage:
            get(action, 'error.message') ||
            'We encountered an error, please try again later.',
        };
        break;
      case constants.HIDE_MODAL_FAQ:
        newState.faqForm = false;
        break;
      case constants.SHOW_PICKUP_CONFIRMATION:
        newState.showPickUpConfirmation = true;
        break;
      case constants.HIDE_PICKUP_CONFIRMATION:
        newState.showPickUpConfirmation = false;
        break;
      case constants.SHOW_APP_PROMOTION_MODAL:
        newState.showAppPromotion = true;
        break;
      case constants.HIDE_APP_PROMOTION_MODAL:
        newState.showAppPromotion = false;
        break;
      case constants.SHOW_SPLASH_AD_MODAL:
        newState.showSplashAd = true;
        newState.currentPopup = action.payload;
        break;
      case constants.HIDE_SPLASH_AD_MODAL:
        newState.showSplashAd = false;
        newState.currentPopup = null;
        break;
      case constants.SHOW_NEW_MC_EXPIRED_MODAL:
        newState.showNewMCExpired = true;
        newState.membershipPayload = action.params;
        break;
      case constants.HIDE_NEW_MC_EXPIRED_MODAL:
        newState.showNewMCExpired = false;
        newState.membershipPayload = null;
        break;
      case constants.SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL:
        newState.showNetPromotersScoreSurvey = true;
        newState.netPromotersScorePayload = action.payload;
        break;
      case constants.HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL:
        newState.showNetPromotersScoreSurvey = false;
        newState.netPromotersScorePayload = null;
        break;
      case constants.SHOW_DELIVERY_ADDRESS_MODAL:
        newState.showDeliveryAddress = {
          isOpen: true,
          isPickup: action.params.isPickup,
        };
        newState.addedProduct = action.params.addedProduct;
        break;
      case constants.HIDE_DELIVERY_ADDRESS_MODAL:
        newState.showDeliveryAddress = null;
        newState.addedProduct = {};
        break;
      case constants.SHOW_SUBSCRIBE_SUCCESS_MODAL:
        newState.showSubscribeSuccess = true;
        break;
      case constants.HIDE_SUBSCRIBE_SUCCESS_MODAL:
        newState.showSubscribeSuccess = false;
        break;
      case constants.SHOW_PAYMENT_CARD_MODAL:
        newState.showPaymentCard = action.params;
        break;
      case constants.HIDE_PAYMENT_CARD_MODAL:
        newState.showPaymentCard = null;
        break;
      case constants.SHOW_CARD_SAVED_MODAL:
        newState.showCardSaved = true;
        break;
      case constants.HIDE_CARD_SAVED_MODAL:
        newState.showCardSaved = false;
        newState.showCardSavedSuccess = false;
        break;
      case constants.SHOW_CARD_SAVED_SUCCESS:
        newState.showCardSavedSuccess = action.params;
        break;
      case constants.SHOW_REMOVE_CARD_SAVED_MODAL:
        newState.showRemoveCardSaved = { visible: true, card: action.params };
        break;
      case constants.HIDE_REMOVE_CARD_SAVED_MODAL:
        newState.showRemoveCardSaved = null;
        break;

      case constants.SHOW_CVC_TOOLTIP_MODAL:
        newState.showCVCTooltip = true;
        break;
      case constants.HIDE_CVC_TOOLTIP_MODAL:
        newState.showCVCTooltip = false;
        break;

      case constants.SHOW_ADD_CARD_MODAL:
        newState.showAddCard = true;
        break;
      case constants.HIDE_ADD_CARD_MODAL:
        newState.showAddCard = false;
        break;
      case constants.SHOW_ADD_MORE_ITEM_MODAL:
        newState.showAddMoreItemModal = action.payload;
        break;
      case constants.LINK_MEMBERSHIP_SHOW_SUPPORT_MESSAGE:
        newState.showSupportMessage = action.payload;
        break;
      case constants.SHOW_ERROR_MESSGE_MODAL:
        newState.showErrorMessage = action.payload;
        break;
      case constants.SHOW_DROPSHIP_WARNING_MODAL:
        newState.showDropshipWarningMessage = action.payload;
        break;
      case constants.SHOW_REORDER_MODAL_SUCCESS:
        newState.showReorderModal = true;
        newState.reorderDetails = action.payload;
        break;
      case constants.SET_REORDER_PRODUCT_DETAILS:
        newState.reorderDetails = action.payload;
        break;
      case constants.HIDE_REORDER_MODAL:
        newState.showReorderModal = false;
        break;

      case constants.SET_EXPIRED_MC_MODAL:
        newState.tooltipExpiredMC = {
          ...newState.tooltipExpiredMC,
          ...action.payload,
        };
        break;
      case LOGOUT:
        newState.tooltipExpiredMC = initialTotalExpiredMC;
        break;
      case constants.SET_NET_PROMOTERS_SCORE_SUCCESS:
        newState.netPromotersScoreSuccess = action.payload;
        break;
      case constants.SHOW_PRICE_FILTER_MODAL:
        newState.priceFilterDetails = action.payload;
        break;
      case constants.HIDE_PRICE_FILTER_MODAL:
        newState.priceFilterDetails = null;
        break;
      case constants.SHOW_RAFFLE_STEPS_MODAL:
        newState.showRaffleStepsModal = action.payload;
        break;
      case constants.HIDE_RAFFLE_STEPS_MODAL:
        newState.showRaffleStepsModal = null;
        break;
      case constants.SHOW_PREMIUM_REQUIRED_MODAL:
        newState.showPremiumRequiredModal = true;
        break;
      case constants.HIDE_PREMIUM_REQUIRED_MODAL:
        newState.showPremiumRequiredModal = false;
        break;
      case constants.SHOW_LOYALTY_REBATE_MODAL:
        newState.showLoyaltyRebateModal = true;
        break;
      case constants.HIDE_LOYALTY_REBATE_MODAL:
        newState.showLoyaltyRebateModal = false;
        break;
      case constants.SHOW_REBATE_MODULE_MODAL:
        newState.showRebateModuleModal = true;
        break;
      case constants.HIDE_REBATE_MODULE_MODAL:
        newState.showRebateModuleModal = false;
        break;
      case constants.SHOW_EXECUTIVE_MODAL:
        newState.showExecModal = true;
        break;
      case constants.HIDE_EXECUTIVE_MODAL:
        newState.showExecModal = false;
        break;
      case constants.SHOW_PRODUCTB2P1_MODAL:
        newState.showProductB2P1Modal = action.payload;
        break;
      case constants.HIDE_PRODUCTB2P1_MODAL:
        newState.showProductB2P1Modal = false;
        break;
      case constants.SHOW_EXECUTIVE_CONFIRMATION_MODAL:
        newState.showExecutiveConfirmationModal = action.payload;
        break;
      case constants.HIDE_EXECUTIVE_CONFIRMATION_MODAL:
        newState.showExecutiveConfirmationModal = false;
        break;
    }
  });

export default persistReducer(persistConfig, reducer);
