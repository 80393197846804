import checkoutApi from 'api/checkout';
import * as globalActions from 'containers/Landers/actions';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import * as actions from 'containers/Checkout/actions';

import {
  makeSelectCartId,
  makeSelectRebateErrorMsg,
} from 'containers/Checkout/selectors';

import { applyRebate } from 'containers/Checkout/rtk/action';
const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  actionCreator: applyRebate,
  effect: async (action: any, listenerApi: any) => {
    const { dispatch, getState } = listenerApi;
    dispatch(globalActions.showLoader());

    const rebate = action?.payload || 0;
    const getCartId = makeSelectCartId()(getState());
    const rebateErrorMessage = makeSelectRebateErrorMsg()(getState());

    try {
      await dispatch(
        checkoutApi.endpoints.onApplyRebate.initiate({
          method: rebate > 0 ? 'PUT' : 'DELETE',
          quote_id: getCartId,
          rebate,
        }),
      ).unwrap();

      if (rebate > 0 && !!rebateErrorMessage) {
        dispatch(actions.loadRebateErrorMsg(''));
      }
      dispatch(actions.getCartTotals());
    } catch (error) {
    } finally {
      dispatch(globalActions.hideLoader());
    }
  },
});
export default listenerMiddleware;
