import { get } from 'lodash';
import { store } from 'configureStore';

export const getMaxQtyAllowed = (product, isInCart) => {
  let _maxQtyAllowed = get(
    product,
    'extension_attributes.max_qty_allowed',
    null,
  );

  if (isInCart) {
    _maxQtyAllowed =
      product?.max_qty_allowed ||
      product?.extension_attributes?.max_qty_allowed ||
      null;
  }

  if (_maxQtyAllowed !== null) {
    return parseInt(_maxQtyAllowed);
  }

  return _maxQtyAllowed;
};

export const getTempItemsQty = () => {
  const tempItems = store.getState().rncheckout.tempItems;

  return tempItems.reduce((acc, item) => {
    return acc + item.qty;
  }, 0);
};

export const getActiveProductQty = (productSku) => {
  const tempItems = store.getState().rncheckout.cartRules;

  let filteredSku = tempItems.find((item) => {
    return item?.temp_id === productSku;
  });

  return filteredSku?.skus[productSku]?.qty || 0;
};

export const getProductB2P1 = (product) => {
  const promoTag = product?.extension_attributes?.promo_tag;
  return promoTag?.length > 0
    ? promoTag.some((tag) => tag === 'BUY 2 FOR THE PRICE OF 1')
    : false;
};
