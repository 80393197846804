import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as categorySelectors from 'containers/Category/selectors';
import { isEmpty, get, findIndex } from 'lodash';
import request from 'utils/request';
import { PAGE_CONFIG } from 'global-constant';
import axios from 'axios';

import * as actions from './actions';
import * as constant from './constants';
import * as globalActions from 'containers/Landers/actions';
import {
  getBrowseUrl,
  convertResponseObject,
  getBrowseUrlForSubCategories,
  trackBrowseEvent,
  convertFacetObject,
  recordImpressionEvent,
  isUnbxdBrowseEnabled,
} from 'utils/unbxdHelper';
import { getStoreViewUrl } from 'utils/request';

function* onLoadProductFilters(action) {
  const { id, isLoading, isSecondaryFilter } = action.payload;

  const filter = yield select((state) =>
    categorySelectors.makeSelectFilter()(state),
  );

  let requestURL = `${getStoreViewUrl()}/landers-subcategory-filter?cat=${id}&ignore_products=1&product_list_order=null&product_list_dir=ASC&ld_display=undefined`;

  if (filter.brand) {
    requestURL += `&brand=${filter.brand.value}`;
  }
  if (filter.lifestyleBenefits) {
    requestURL += `&lifestyle_benefits=${filter.lifestyleBenefits.value}`;
  }

  try {
    if (isLoading) {
      yield put(
        globalActions.showLoader({
          isLoadingCategory: true,
          isSecondaryFilter,
        }),
      );
    }
    yield put(actions.loadProductCategory());
    let res = yield call(request, requestURL, {
      method: 'GET',
    });

    yield put(actions.loadProductFiltersSuccess(res));
  } catch (error) {
  } finally {
    if (isLoading) {
      yield put(globalActions.hideLoader());
    }
  }
}

function* onLoadSubCategories(action) {
  const {
    id,
    pageSize = PAGE_CONFIG.pageSize,
    isLoadMore,
    isIgnoreFilter,
    isIgnoreProducts,
    isSecondaryFilter,
  } = action.payload;
  let currentPage = yield select((state) =>
    categorySelectors.makeSelectCurrentPage()(state),
  );
  if (isLoadMore) {
    currentPage++;
  } else {
    currentPage = 1;
  }

  let requestURL = `${getStoreViewUrl()}/landers-subcategory-filter?cat=${id}`;

  const filter = yield select((state) =>
    categorySelectors.makeSelectFilter()(state),
  );
  const secondaryFilter = yield select((state) =>
    categorySelectors.makeSelectSecondaryFilter()(state),
  );

  const productsCategoryCurrent = yield select((state) =>
    categorySelectors.makeSelectProductList()(state),
  );
  if (!isIgnoreProducts || isLoadMore) {
    requestURL += `&page_size=${pageSize}&current_page=${currentPage}`;
  }
  if (isIgnoreFilter) {
    requestURL += `&ignore_filter=1`;
  }
  if (isIgnoreProducts) {
    requestURL += `&ignore_products=1`;
  }
  if (filter.brand) {
    requestURL += `&brand=${filter.brand.value}`;
  }
  if (filter.lifestyleBenefits) {
    requestURL += `&lifestyle_benefits=${filter.lifestyleBenefits.value}`;
  }
  if (!isIgnoreProducts && filter.price) {
    requestURL += `&price=${filter.price[0]}-${filter.price[1]}`;
  }
  if (secondaryFilter.product_list_order === 'price_desc') {
    requestURL += '&product_list_order=price';
  } else {
    let product_list_order = secondaryFilter.product_list_order;
    if (String(product_list_order).includes('+')) {
      product_list_order = String(product_list_order).replace('+', '');
    } else if (String(product_list_order).includes('-')) {
      product_list_order = String(product_list_order).replace('-', '');
    }
    requestURL += `&product_list_order=${product_list_order || null}`;
  }
  requestURL += `&product_list_dir=${secondaryFilter.product_list_dir}&ld_display=${secondaryFilter.ld_display}`;

  try {
    // support show placeholder / loading
    if (currentPage === 1) {
      yield put(
        globalActions.showLoader({
          isLoadingCategory: true,
          isSecondaryFilter,
        }),
      );
    } else {
      yield put(
        globalActions.showLoader({
          isLoadMoreCategory: true,
        }),
      );
    }
    yield put(actions.loadProductCategory());
    let res = yield call(request, requestURL, {
      method: 'GET',
    });

    if (!isIgnoreProducts) {
      const productsData = get(res, 'products_data', []);
      res.isEndOfCategoryProduct = isEmpty(productsData);
      if (currentPage !== 1) {
        // update new list product when load more
        res.products_data = [...productsCategoryCurrent, ...productsData];
      }
      yield put(actions.saveProductList(res.products_data, currentPage));
    }

    if (!isIgnoreFilter) {
      yield put(actions.loadSubCategoriesSuccess(res));
    }
  } catch (error) {
    yield put(actions.loadSubCategoriesFailed(error));
  } finally {
    yield put(globalActions.hideLoader());
  }
}

const callUnbxd = async (url) => {
  return axios.get(url);
};

function* onLoadUnbxdCategories(action) {
  console.log('Unbxd Categories loader');
  const { id, isLoadMore, isIgnoreProducts, isSecondaryFilter } =
    action.payload;
  let categoryViewState = yield select((state) =>
    categorySelectors.makeSelectCategory()(state),
  );
  let currentPage = yield select((state) =>
    categorySelectors.makeSelectCurrentPage()(state),
  );

  const productsCategoryCurrent = yield select((state) =>
    categorySelectors.makeSelectProductList()(state),
  );
  if (isLoadMore) {
    currentPage++;
  } else {
    currentPage = 1;
  }

  let requestURL = getBrowseUrl(categoryViewState, action, currentPage); // get Browse URL
  if (isIgnoreProducts) {
    requestURL = `${getStoreViewUrl()}/landers-subcategory-filter?cat=${id}&ignore_products=${isIgnoreProducts}`;
  }
  try {
    // support show placeholder / loading
    if (currentPage === 1) {
      yield put(
        globalActions.showLoader({
          isLoadingCategory: true,
          isSecondaryFilter,
        }),
      );
    } else {
      yield put(
        globalActions.showLoader({
          isLoadMoreCategory: true,
        }),
      );
    }
    //let res = {}// get product result;
    //const productsData = []; // get product list
    yield put(actions.loadProductCategory());
    let result = yield callUnbxd(requestURL);
    let res = {};
    if (isIgnoreProducts) {
      // Category Level 1 loading
      res = result.data;
    } else {
      const productsData = convertResponseObject(result).data;
      res.isEndOfCategoryProduct = isEmpty(productsData);
      if (!res.isEndOfCategoryProduct && !isLoadMore) {
        res.unbxd_filter = convertFacetObject(result).unbxd_filter;
      }
      if (currentPage !== 1) {
        // update new list product when load more
        res.products_data = [...productsCategoryCurrent, ...productsData];
      } else {
        res.products_data = productsData;
      }
      if (productsData) {
        recordImpressionEvent(requestURL, result);
        trackBrowseEvent(categoryViewState, action);
      }
    }

    yield put(actions.saveProductList(res.products_data, currentPage));
    if (!isLoadMore) {
      yield put(actions.loadSubCategoriesSuccess(res, currentPage));
    }
  } catch (error) {
    yield put(actions.loadSubCategoriesFailed(error));
  } finally {
    yield put(globalActions.hideLoader());
  }
}

function* onLoadUnbxdProductCategory(action) {
  if (!action.payload) {
    return;
  }
  const requestURL = getBrowseUrlForSubCategories(action);
  try {
    let result = yield callUnbxd(requestURL);
    let res = {};
    res.products_data = convertResponseObject(result).data;
    yield put(
      actions.saveProductCategory({
        id: action.payload,
        products_data: convertResponseObject(result).data,
      }),
    );
  } catch (err) {
  } finally {
  }
}

function* onLoadProductCategory(action) {
  if (!action.payload) {
    return;
  }
  const requestURL = `${getStoreViewUrl()}/landers-subcategory-product?cat=${
    action.payload
  }`;
  try {
    const res = yield call(request, requestURL, { method: 'GET' });
    yield put(
      actions.saveProductCategory({ id: action.payload, products_data: res }),
    );
  } catch (err) {
  } finally {
  }
}

export default function* homeSaga() {
  if (isUnbxdBrowseEnabled()) {
    yield takeLatest(constant.LOAD_SUB_CATEGORY, onLoadUnbxdCategories);
    yield takeEvery(constant.LOAD_PRODUCT_CATEGORY, onLoadUnbxdProductCategory);
  } else {
    yield takeLatest(constant.LOAD_SUB_CATEGORY, onLoadSubCategories);
    yield takeEvery(constant.LOAD_PRODUCT_CATEGORY, onLoadProductCategory);
    yield takeLatest(constant.LOAD_PRODUCT_FILTERS, onLoadProductFilters);
  }
}
