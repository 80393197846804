import accountApi from 'api/account';

import { GET_REBATE_UPDATE } from 'containers/Account/constants';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
  getRebateUpdate,
  getRebateUpdateSuccess,
  seenRebateUpdate,
} from 'containers/Account/rtk/slice';
import { makeSelectGetRebateHasUpdate } from '../selectors';
import { makeSelectCurrentUser } from 'containers/Landers/selectors';
import { checkMCBizExecutive } from 'utils/helper';
const listenerMiddleware = createListenerMiddleware();
//GET REBATE UPDATE FOR RED DOT
listenerMiddleware.startListening({
  actionCreator: getRebateUpdate,
  effect: async (_, { dispatch, getState }) => {
    const currentUser = makeSelectCurrentUser()(getState());
    const isMCBizOrExecutive = checkMCBizExecutive(currentUser);

    if (!!isMCBizOrExecutive) {
      try {
        const response = await dispatch(
          accountApi.endpoints.getUnseenRebates.initiate(_, {
            forceRefetch: true,
          }),
        ).unwrap();

        if (!!response?.data?.has_unseen_rebates) {
          dispatch(accountApi.util.invalidateTags(['RebateDetails']));
          dispatch(getRebateUpdateSuccess(true));
        }
      } catch (error) {}
    }
  },
});

//SEEN REBATE UPDATE FOR RED DOT
listenerMiddleware.startListening({
  actionCreator: seenRebateUpdate,
  effect: async (action: any, { dispatch, getState }) => {
    const hasRebateUpdate = makeSelectGetRebateHasUpdate()(getState());

    //Only execute when there is update on Rebate transaction
    if (!!hasRebateUpdate) {
      try {
        await dispatch(
          accountApi.endpoints.onSeenRebateUpdate.initiate(
            action?.payload || '',
          ),
        ).unwrap();
        dispatch(getRebateUpdateSuccess(false));
      } catch (error) {}
    }
  },
});

export default listenerMiddleware;
