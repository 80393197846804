/*
 *
 * Checkout actions
 *
 */
import * as constants from './constants';

// CREATE_CART
export function createCart(params = {}) {
  return {
    type: constants.CREATE_CART,
    params,
  };
}

export function createCartSuccess(payload) {
  return {
    type: constants.CREATE_CART_SUCCESS,
    payload,
  };
}

export function createCartFailed(error) {
  return {
    type: constants.CREATE_CART_FAILED,
    error,
  };
}

// GET_CART_INFO
export function getCartInfo(params = {}) {
  const {
    needReloadTotals = false,
    redirectTo = '',
    syncProductNoRedirect = false,
    needValidate = false,
    isInCheckoutPage = false,
  } = params;

  return {
    type: constants.GET_CART_INFO,
    needReloadTotals,
    redirectTo,
    syncProductNoRedirect,
    needValidate,
    isInCheckoutPage,
  };
}

export function getCartInfoSuccess(payload) {
  return {
    type: constants.GET_CART_INFO_SUCCESS,
    payload,
  };
}

export function getCartInfoFailed(error) {
  return {
    type: constants.GET_CART_INFO_FAILED,
    error,
  };
}

// GET_CART_TOTALS
export function getCartTotals(payload = {}) {
  return {
    type: constants.GET_CART_TOTALS,
    payload,
  };
}

export function getCartTotalsSuccess(payload) {
  return {
    type: constants.GET_CART_TOTALS_SUCCESS,
    payload,
  };
}

export function getCartTotalsFailed(error) {
  return {
    type: constants.GET_CART_TOTALS_FAILED,
    error,
  };
}

export function setLoadingCartTotals(payload) {
  return {
    type: constants.SET_LOADING_CART_TOTALS,
    payload,
  };
}

// VALIDATE_CART_ITEMS
export function validateCartItems() {
  return {
    type: constants.VALIDATE_CART_ITEMS,
  };
}

export function validateCartItemsSuccess(payload) {
  return {
    type: constants.VALIDATE_CART_ITEMS_SUCCESS,
    payload,
  };
}

export function validateCartItemsFailed(error) {
  return {
    type: constants.VALIDATE_CART_ITEMS_FAILED,
    error,
  };
}

// SYNC_CART_SERVER
/**
 *
 * @param {number} delay
 * @param {boolean} needReloadTotals
 * @param {boolean} needReloadCartInfo
 * @param {boolean} redirectCart
 * @param {boolean} isCartUpdate
 * @returns
 */
export function syncCartServer(
  delay = 3000,
  needReloadTotals = false,
  needReloadCartInfo = false,
  redirectCart = false,
  isCartUpdate = false,
  products = [],
) {
  return {
    type: constants.SYNC_CART_SERVER,
    delay,
    needReloadTotals,
    needReloadCartInfo,
    redirectCart,
    isCartUpdate,
    products,
  };
}

export function syncCartServerSuccess(payload) {
  return {
    type: constants.SYNC_CART_SERVER_SUCCESS,
    payload,
  };
}

export function syncCartServerFailed(error) {
  return {
    type: constants.SYNC_CART_SERVER_FAILED,
    error,
  };
}

// GET_CART_RULES
export function getCartRules(actionSync = null, delay = 200) {
  return {
    type: constants.GET_CART_RULES,
    delay,
    actionSync,
  };
}

export function getCartRulesSuccess(payload) {
  return {
    type: constants.GET_CART_RULES_SUCCESS,
    payload,
  };
}

export function getCartRulesFailed(error) {
  return {
    type: constants.GET_CART_RULES_FAILED,
    error,
  };
}

export function clearCartRules() {
  return {
    type: constants.CLEAR_CART_RULES,
  };
}

// GET_DELIVERY_TIMESLOTS
export function getDeliveryTimeslots(payload = {}) {
  return {
    type: constants.GET_DELIVERY_TIMESLOTS,
    payload,
  };
}

export function getDeliveryTimeslotsSuccess(payload) {
  return {
    type: constants.GET_DELIVERY_TIMESLOTS_SUCCESS,
    payload,
  };
}

export function getDeliveryTimeslotsFailed(error) {
  return {
    type: constants.GET_DELIVERY_TIMESLOTS_FAILED,
    error,
  };
}

// GET_STORES_TIME_SLOTS
export function getStoresTimeSlots(params) {
  return {
    type: constants.GET_STORES_TIME_SLOTS,
    params,
  };
}

export function getStoresTimeSlotsSuccess(payload) {
  return {
    type: constants.GET_STORES_TIME_SLOTS_SUCCESS,
    payload,
  };
}

export function getStoresTimeSlotsFailed(error) {
  return {
    type: constants.GET_STORES_TIME_SLOTS_FAILED,
    error,
  };
}

// SELECT_DELIVERY_TIMESLOT
export function selectDeliveryTimeslot(timeslot) {
  return {
    type: constants.SELECT_DELIVERY_TIMESLOT,
    timeslot,
  };
}

// VALIDATE_DELIVERY_TIMESLOT
export function validateDeliveryTimeslot(payload) {
  return {
    type: constants.VALIDATE_DELIVERY_TIMESLOT,
    payload: {
      isPickup: payload?.isPickup || false,
    },
  };
}

export function validateDeliveryTimeslotSuccess(payload) {
  return {
    type: constants.VALIDATE_DELIVERY_TIMESLOT_SUCCESS,
    payload,
  };
}

export function validateDeliveryTimeslotFailed(error) {
  return {
    type: constants.VALIDATE_DELIVERY_TIMESLOT_FAILED,
    error,
  };
}

// GET_SHIPPING_METHODS
export function getShippingMethods(cartId = 'mine') {
  return {
    type: constants.GET_SHIPPING_METHODS,
    cartId,
  };
}

export function getShippingMethodsSuccess(payload) {
  return {
    type: constants.GET_SHIPPING_METHODS_SUCCESS,
    payload,
  };
}

export function getShippingMethodsFailed(error) {
  return {
    type: constants.GET_SHIPPING_METHODS_FAILED,
    error,
  };
}

// ESTIMATE_SHIPPING_METHODS
export function estimateShippingMethods() {
  return {
    type: constants.ESTIMATE_SHIPPING_METHODS,
  };
}

export function estimateShippingMethodsSuccess(payload) {
  return {
    type: constants.ESTIMATE_SHIPPING_METHODS_SUCCESS,
    payload,
  };
}

export function estimateShippingMethodsFailed(error) {
  return {
    type: constants.ESTIMATE_SHIPPING_METHODS_FAILED,
    error,
  };
}

// SET_SHIPPING_ADDRESS
export function setShippingAddress(address) {
  return {
    type: constants.SET_SHIPPING_ADDRESS,
    address,
  };
}

// UPDATE_SHIPPING_ADDRESS
export function updateShippingAddress(triggerFee = false, params = {}) {
  return {
    type: constants.UPDATE_SHIPPING_ADDRESS,
    triggerFee,
    params,
  };
}

export function updateShippingAddressSuccess(payload) {
  return {
    type: constants.UPDATE_SHIPPING_ADDRESS_SUCCESS,
    payload,
  };
}

export function updateShippingAddressFailed(error) {
  return {
    type: constants.UPDATE_SHIPPING_ADDRESS_FAILED,
    error,
  };
}

// SET_PAYMENT_METHOD
export function setPaymentMethod(method) {
  return {
    type: constants.SET_PAYMENT_METHOD,
    method,
  };
}

// UPDATE_PAYMENT_INFORMATION
export function updatePaymentInformation(
  cartId = 'mine',
  cartHasOnlyMembership,
  cardInfo,
) {
  return {
    type: constants.UPDATE_PAYMENT_INFORMATION,
    cartId,
    cartHasOnlyMembership,
    cardInfo,
  };
}

export function updatePaymentInformationSuccess(payload) {
  return {
    type: constants.UPDATE_PAYMENT_INFORMATION_SUCCESS,
    payload,
  };
}

export function updatePaymentInformationFailed(error) {
  return {
    type: constants.UPDATE_PAYMENT_INFORMATION_FAILED,
    error,
  };
}

export function cancelUpdatePaymentInformation() {
  return {
    type: constants.CANCEL_UPDATE_PAYMENT_INFORMATION,
  };
}

// GET_ORDER_BYID
export function getOrderById(requestData = {}) {
  return {
    type: constants.GET_ORDER_BYID,
    orderId: requestData.orderId,
    reloadShippingAddress: requestData.reloadShippingAddress,
  };
}

export function getOrderByIdSuccess(payload) {
  return {
    type: constants.GET_ORDER_BYID_SUCCESS,
    payload,
  };
}

export function clearOrderId() {
  return {
    type: constants.CLEAR_ORDER_ID,
  };
}

export function getOrderByIdFailed(error) {
  return {
    type: constants.GET_ORDER_BYID_FAILED,
    error,
  };
}

// GET_PAYMENT_METHODS
export function getPaymentMethods() {
  return {
    type: constants.GET_PAYMENT_METHODS,
  };
}

export function getPaymentMethodsSuccess(payload) {
  return {
    type: constants.GET_PAYMENT_METHODS_SUCCESS,
    payload,
  };
}

export function getPaymentMethodsFailed(error) {
  return {
    type: constants.GET_PAYMENT_METHODS_FAILED,
    error,
  };
}

// ADD_PAYMENT_CARDS
export function AddPaymentCard(params) {
  return {
    type: constants.ADD_PAYMENT_CARDS,
    params,
  };
}

export function AddPaymentCardSuccess(payload) {
  return {
    type: constants.ADD_PAYMENT_CARDS_SUCCESS,
    payload,
  };
}

export function AddPaymentCardFailed(error) {
  return {
    type: constants.ADD_PAYMENT_CARDS_FAILED,
    error,
  };
}

export function getMediaPathSuccess(payload) {
  return {
    type: constants.GET_MEDIA_PATH_SUCCESS,
    payload,
  };
}

// APPLY_COUPON
export function applyCoupon(payload = {}) {
  return {
    type: constants.APPLY_COUPON,
    payload,
  };
}

export function applyCouponSuccess(payload) {
  return {
    type: constants.APPLY_COUPON_SUCCESS,
    payload,
  };
}

export function applyCouponFailed(error) {
  return {
    type: constants.APPLY_COUPON_FAILED,
    error,
  };
}

// GET LAST ORDER OOS
export function getLastOrderOOS(payload = {}) {
  return {
    type: constants.GET_LAST_ORDER_OOS,
    payload,
  };
}

export function getLastOrderOOSSuccess(payload) {
  return {
    type: constants.GET_LAST_ORDER_OOS_SUCCESS,
    payload,
  };
}

export function getLastOrderOOSFailed(error) {
  return {
    type: constants.GET_LAST_ORDER_OOS_FAILED,
    error,
  };
}

/**
 * Sync and update new products qty to current client cart
 * @constructor
 * @param {Array} products - list of new updated products and qtys. [{ data: {}, qty: 1 }]
 */
export function syncCartClient(products, params = {}) {
  const { isSetCartAsNeedSync = true } = params;

  return {
    type: constants.SYNC_CART_CLIENT,
    products,
    delay: params.delay || 3000,
    needReloadTotals: params.needReloadTotals,
    actionSync: params.actionSync,
    isOutOfStock: params.isOutOfStock,
    outOfStockSku: params.outOfStockSku,
    redirectCart: params.redirectCart || false,
    isCartUpdate: params.isCartUpdate || false,
    isSetCartAsNeedSync: isSetCartAsNeedSync,
  };
}

/**
 * Sync product to server and navigate to checkout
 * @constructor
 */
export function syncProductThenGoCheckout(params = {}) {
  return {
    type: constants.SYNC_PRODUCT_THEN_GOCHECKOUT,
    noRedirectCart: params?.noRedirectCart || false,
    isReloadCartInfo: params?.isReloadCartInfo || true,
    isInCheckoutPage: params?.isInCheckoutPage || false,
  };
}

/**
 * remove all products in cart
 * @constructor
 * @param {Array} products - list of new updated products and qtys. [{ data: {}, qty: 1 }]
 */
export function removeAllProductsInCart(payload) {
  return {
    type: constants.REMOVE_ALLPRODUCTS_INCART,
    cartId: payload.cartId,
    cartItemsCount: payload.cartItemsCount,
  };
}

export function removeProductInCart(payload) {
  return {
    type: constants.REMOVE_PRODUCT_IN_CART,
    cartId: payload.cartId,
    productId: payload.productId,
    productQty: payload.productQty,
    needReloadTotals: payload.needReloadTotals,
  };
}

export function removeMultipleProductsInCart(payload) {
  let redirectInCart = payload.redirectCart ? true : false;
  return {
    type: constants.REMOVE_MULTIPLE_PRODUCTS_IN_CART,
    cartId: payload.cartId,
    invalidCartItems: payload.invalidCartItems,
    redirectCart: redirectInCart,
  };
}

/**
 * refresh client cart data when payment successfully
 * @constructor
 */
export function setCartAsNeedSync(status) {
  return {
    type: constants.SET_CART_AS_NEED_SYNC,
    status,
  };
}

/**
 * refresh client cart data when payment successfully
 */
export function restoreCart(params = {}) {
  return {
    type: constants.RESTORE_CART,
    noRedirectCart: params.noRedirectCart,
  };
}

export function removeShippingAddressInvalidQuantities() {
  return {
    type: constants.REMOVE_SHIPPING_ADDRESS_INVALID_QUANTITIES,
  };
}

export const setCancellationOption = (payload) => ({
  type: constants.SET_CANCELLATION_OPTION,
  payload,
});

export const setDefaultCancellationOption = (payload) => ({
  type: constants.SET_DEFAULT_CANCELLATION_OPTION,
  payload,
});

export const setStorePickupAddress = (payload) => ({
  type: constants.SET_STORE_PICKUP_ADDRESS,
  payload,
});

export const setFeeTooltipVisibility = (payload) => ({
  type: constants.SET_FEE_TOOLTIP_VISIBILITY,
  payload,
});

export const isRestoreCartProcessing = (payload) => {
  return {
    type: constants.IS_RESTORE_CART_PROCESSING,
    payload,
  };
};

export const isSubmittedPayment = (payload) => {
  return {
    type: constants.IS_SUBMITTED_PAYMENT,
    payload,
  };
};

export function setPaymentCard(card) {
  return {
    type: constants.SET_PAYMENT_CARD,
    card,
  };
}

// GET_PAYMENT_CARDS
export function getPaymentCards() {
  return {
    type: constants.GET_PAYMENT_CARDS,
  };
}

export function getPaymentCardsSuccess(payload) {
  return {
    type: constants.GET_PAYMENT_CARDS_SUCCESS,
    payload,
  };
}

export function getPaymentCardsFailed(error) {
  return {
    type: constants.GET_PAYMENT_CARDS_FAILED,
    error,
  };
}

export function showSlowCartLoading(payload) {
  return {
    type: constants.SHOW_SLOW_CART_LOADING,
    payload,
  };
}

export function hideSlowCartLoading(error) {
  return {
    type: constants.HIDE_SLOW_CART_LOADING,
    error,
  };
}

export function getVoucherMessage(payload = {}) {
  return {
    type: constants.GET_VOUCHER_MESSAGE,
    payload,
  };
}

export function getVoucherMessageSuccess(payload = {}) {
  return {
    type: constants.GET_VOUCHER_MESSAGE_SUCCESS,
    payload,
  };
}

export function removeVoucherMessage() {
  return {
    type: constants.REMOVE_VOUCHER_MESSAGE,
  };
}

export function updateCart(payload) {
  return {
    type: constants.UPDATE_CART,
    payload,
  };
}

export function setCartItemsCount(payload) {
  return {
    type: constants.SET_CART_ITEMS_COUNT,
    payload,
  };
}

export function setIsSyncingProduct(payload) {
  return {
    type: constants.SET_IS_SYNCING_PRODUCT,
    payload,
  };
}

export function runPaymentSuccessProcess({ orderId }) {
  return {
    type: constants.RUN_PAYMENT_SUCCESS_PROCESS,
    payload: {
      orderId,
    },
  };
}

export function updateAddressBeforeSyncing(payload) {
  return {
    type: constants.UPDATE_ADDRESS_BEFORE_SYNCING,
    payload,
  };
}

export function syncCartToMgt() {
  return {
    type: constants.SYNC_CART_TO_MGT,
  };
}

export function syncCartToMgtFinished() {
  return {
    type: constants.SYNC_CART_MGT_FINISHED,
  };
}

export function savePayPlaceOrder(payload) {
  return {
    type: constants.SAVE_PAY_PLACE_ORDER,
    payload,
  };
}

export function savePayPlaceOrderSuccess(payload) {
  return {
    type: constants.SAVE_PAY_PLACE_ORDER_SUCCESS,
    payload,
  };
}

export function loadDeliveryAddressScreen(payload) {
  return {
    type: constants.LOAD_DELIVERY_ADDRESS_SCREEN,
    payload,
  };
}

export function calculateBizRebate() {
  return {
    type: constants.CALCULATE_BIZ_REBATE,
  };
}

export function clearBizRebate() {
  return {
    type: constants.CLEAR_BIZ_REBATE,
  };
}

export function updateTempItems(payload = {}) {
  return {
    type: constants.UPDATE_TEMP_ITEMS,
    payload,
  };
}

export function applyRebate(payload) {
  return {
    type: constants.APPLY_REBATE,
    payload,
  };
}

export function loadRebateErrorMsg(payload) {
  return {
    type: constants.LOAD_REBATE_ERROR_MESSAGE,
    payload,
  };
}
