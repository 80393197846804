import { createAction } from '@reduxjs/toolkit';
import * as constants from '../constants';

export const applyRebate = createAction(
  constants.APPLY_REBATE,
  function prepare(rebate: number) {
    return {
      payload: rebate,
    };
  },
);
